/* eslint-disable no-console */
import { library } from '@fortawesome/fontawesome-svg-core';
import * as fas from '@fortawesome/free-solid-svg-icons';
import * as far from '@fortawesome/free-regular-svg-icons';
import * as fasP from '@fortawesome/pro-solid-svg-icons';
import * as farP from '@fortawesome/pro-regular-svg-icons';
import * as fal from '@fortawesome/pro-light-svg-icons';
// import * as freeRegularIcons from '@fortawesome/free-regular-svg-icons';

export default () => {
  library.add(
    fal.faCheck,
    fal.faCheckCircle,
    fal.faCopy,
    fal.faIndustryAlt,
    fal.faListAlt,
    fal.faStarHalfAlt,
    far.faCircle,
    far.faUserCircle,
    farP.faAnalytics,
    farP.faCheckCircle,
    farP.faChevronDown,
    farP.faChevronUp,
    farP.faClipboard,
    farP.faClipboardUser,
    farP.faClipboardUser,
    farP.faClipboardUser,
    farP.faIndustryAlt,
    farP.faPencil,
    farP.faUserChart,
    farP.faUsers,
    fas.faArrowLeft,
    fas.faArrowRight,
    fas.faArrowUp,
    fas.faCaretDown,
    fas.faCaretUp,
    fas.faCheckCircle,
    fas.faCheckSquare,
    fas.faChevronDown,
    fas.faChevronLeft,
    fas.faChevronRight,
    fas.faCircle,
    fas.faClipboardCheck,
    fas.faCog,
    fas.faDotCircle,
    fas.faDownload,
    fas.faEllipsisH,
    fas.faEllipsisV,
    fas.faEnvelope,
    fas.faEye,
    fas.faEyeSlash,
    fas.faFileAlt,
    fas.faForward,
    fas.faHome,
    fas.faMinusSquare,
    fas.faMoneyBill,
    fas.faNetworkWired,
    fas.faPause,
    fas.faPencilAlt,
    fas.faPlay,
    fas.faPlus,
    fas.faQuestion,
    fas.faQuestionCircle,
    fas.faRandom,
    fas.faSearch,
    fas.faSignOutAlt,
    fas.faSlidersH,
    fas.faSquare,
    fas.faStar,
    fas.faStop,
    fas.faTimes,
    fas.faTrash,
    fas.faTrashAlt,
    fas.faUpload,
    fas.faUserCircle,
    fas.faChartLine,
    fasP.faAnalytics,
    fasP.faCaretCircleRight,
    fasP.faFileChartLine,
    fasP.faFlagAlt,
    fasP.faIndustryAlt,
    fasP.faPencil,
    fasP.faSave,
    fasP.faSpinnerThird,
    fasP.faUserChart,
    fasP.faUsers,
    fasP.faCommentAltDots,
  );
};
