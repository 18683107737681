import React from 'react';

import { useSelector } from 'react-redux';

import flatten from 'flat';
import { IntlProvider } from 'react-intl';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import moment from 'moment';
import MomentUtils from '@date-io/moment';
import spanishLocale from 'moment/locale/es';
import mexicanSpanishLocale from 'moment/locale/es-mx';
import brazilianPortugueseLocale from 'moment/locale/pt-br';

import messages from '../i18n/messages/messages';
import { userSelectors } from '../redux/selectors';

import App from './App';

const TranslationMiddleWare = () => {
  const activeLanguage = useSelector(userSelectors.activeLanguage) || 'pt-BR';

  moment.locale('pt-br', brazilianPortugueseLocale);
  moment.locale('es', spanishLocale);
  moment.locale('es-mx', mexicanSpanishLocale);
  moment.locale((activeLanguage || 'pt-br'));

  return (
    <>
      <IntlProvider
        defaultLocale='pt-BR'
        locale={(activeLanguage || 'pt-BR')}
        messages={flatten(
          messages[(activeLanguage || 'pt-BR')]
        || messages['pt-BR'],
        )}
      >
        <MuiPickersUtilsProvider utils={MomentUtils} locale={(activeLanguage || 'pt-BR').toLowerCase()}>
          <App />
        </MuiPickersUtilsProvider>

      </IntlProvider>

    </>
  );
};

export default TranslationMiddleWare;
