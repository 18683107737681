export default {
  criar_conta: {
    simple: 'Sign up',
  },

  entrar: {
    simple: 'Sign in',
  },

  olar: {
    simple: 'Hello!',
    placeholder: 'Hello {name}!',
  },

  plural: {
    simple: 'I have {num, plural, one { cat} other { cats}}',
  },

  select: {
    placeholder: 'Selecione',
    simple: 'I am a {gender, select, male {man} female {woman}}',
  },

  layout_header: {
    options: {
      organizacional_profile: 'Perfil da organização',
      my_profile: 'Minha conta',
      respondents: 'Respondentes da organização',
      managers: 'Gestores',
    },
    assessments: 'Avaliações',
    choose_view_mode: 'Alternar para {isManagerView, select, true {respondente} false {gestor}}',
    sign_out: 'Sair da minha conta',
    help: 'Preciso de ajuda',
    see_all_companies: 'Ver todas as empresas',
    answer_assessments: 'Responder avaliações',
  },

  statuses: {
    questionnaires: {
      created: 'Criado',

      ready_to_start: 'Não {gender, select, male {Iniciado} female {Iniciada}}',
      started: '{gender, select, male {Iniciado} female {Iniciada}}',
      answered: '{gender, select, male {Respondido} female {Respondida}}',

      not_answered: '{gender, select, female {Não respondida} male {Não respondido} }',
      partially_answered: 'Não {gender, select, male {Finalizado} female {Finalizada}}',
      results_available: 'Ver Resultado',
    },

    assessments: {
      released: 'Liberada',
      paused: 'Pausada',
      finished: 'Finalizada',
      results: 'Resultados liberados',
    },

    see_all_companies: {
      companies: '{num, plural, one {Empresa} other {Empresas}}',
      desisted: '{num, plural, one {Desistiu} other {Desistiram}}',
      in_progress: '{num, plural, one {Liberada} other {Liberadas}}',
      paused: '{num, plural, one {Pausada} other {Pausadas}}',
      finished: '{num, plural, one {Finalizada} other {Finalizadas}}',
      results_available: '{num, plural, one {Ver resultado} other {Ver resultados}}',
    },

    respondents: {
      pending: 'Pendente',
      registered: 'Cadastrado',
      not_answered: 'Não Respondido',
      answered: 'Respondido',
    },

    assessment_subtype: {
      self_assessment: 'Auto',
      180: '180',
      270: '270',
      360: '360',
      720: '720',
    },
  },

  user_roles: {
    manager: 'Gestor',
    owner: 'Gestor Principal',
    respondent: 'Respondente',
  },

  errors: {
    required: {
      simple: 'Campo é obrigatório',
      email: 'Email é obrigatório',
      password: 'Senha é obrigatória',
      cpf: 'CPF é obrigatório',
      cnpj: 'CNPJ é obrigatório',
      first_name: 'Nome é obrigatório',
      last_name: 'Sobrenome é obrigatório',
    },

    invalid: {
      start_date: 'A data deve ser maior que hoje',
      end_date: 'A data deve ser maior ou igual a data de início',
      simple: 'Campo inválido',
      email: 'Email inválido',
      cpf: 'CPF inválido',
      cep: 'CEP inválido',
      cnpj: 'CNPJ inválido',
      url: 'Url inválido',
      value: 'Valor não permitido',
    },

    invalid_fields: 'Suas mudanças não serão salvas caso existam erros no formulário!',
    generic_error_message: 'Poxa, ocorreu um erro ao processar sua requisição! Se persistir, favor entrar em contato através do menu suporte',
    file_too_big: 'Arquivo maior do que {num} MB',
    wrong_mime: 'Tipo de arquivo inválido',
    passwords_do_not_match: 'Confirmação não corresponde com senha inserida',
    min_string_len: 'Mínimo de {num, plural, one {# caractere} other {# caracteres}}',
    max_string_len: 'Máximo de {num, plural, one {# caractere} other {# caracteres}}',
    min_uppercase_letters: 'Mínimo de {num, plural, one {# letra maiúscula} other {# letras maiúsculas}}',
    min_lowercase_letters: 'Mínimo de {num, plural, one {# letra minúscula} other {# letras minúsculas}}',
  },

  select_options: {
    // vou deixar estas chaves em pt para facilitar
    genders: {
      female: 'Mulher',
      male: 'Homem',
      non_binary: 'Não Binário',
      fluid: 'Fluído',
      other: 'Outro',
      undeclared: 'Prefiro não declarar',
    },

    sex: {
      female: 'Feminino',
      male: 'Masculino',
      intersexual: 'Intersexual',
      other: 'Outro',
    },

    orientation: {
      assexual: 'Assexual',
      bissexual: 'Bissexual',
      straight: 'Heterossexual',
      homosexual: 'Homossexual',
      pansexual: 'Pansexual',
      fluid: 'Fluida',
      other: 'Outro',
      undeclared: 'Prefiro não declarar',
    },

    race: {
      yellow: 'Amarelo',
      white: 'Branco',
      indian: 'Indígena',
      pardo: 'Pardo',
      black: 'Preto',
      undeclared: 'Prefiro não declarar',
    },

    pcd: {
      nao_pcd: 'Não sou PcD',
      deficiencia_fisica: 'Deficiência física',
      deficiencia_auditivo: 'Deficiência auditiva',
      deficiencia_visual: 'Deficiência visual',
      deficiencia_mental: 'Deficiência mental',
      deficiencia_multipla: 'Deficiência múltipla',
      undeclared: 'Prefiro não declarar',
    },

    job_titles: {
      estagiario: 'Estágio',
      professor: 'Professor(a)',
      consultoria: 'Consultoria',
      supervisao: 'Supervisão',
      analista: 'Analista',
      assistente: 'Assistente',
      auxiliar: 'Auxiliar',
      supervisor: 'Supervisor',
      coordenador: 'Coordenação',
      gerente: 'Gerência',
      senior: 'Especialista Sênior',
      diretor: 'Direção (C-Level)',
      conselho: 'Conselho',
      prestador_de_servicos: 'Prestador(a) de serviços',
      other: 'Outro',
    },

    departments: {
      administracao: 'Administração',
      assistencia_ou_suporte_tecnico: 'Assistência ou suporte técnico',
      atendimento: 'Atendimento',
      auditoria: 'Auditoria e consultoria',
      comercial: 'Comercial',
      compras: 'Compras',
      complience: 'Compliance',
      comunicacao_e_assessoria: 'Comunicação e assessoria',
      controladoria: 'Controladoria',
      contabilidade: 'Contabilidade',
      contratos: 'Contratos',
      engenharia: 'Engenharia',
      estrategia: 'Estratégia',
      financeiro: 'Financeiro',
      inovacao: 'Inovação',
      inteligência: 'Inteligência',
      juridico: 'Jurídico',
      logistica: 'Logística',
      manutencao: 'Manutenção',
      marketing: 'Marketing',
      melhoria: 'Melhoria',
      novos_negocios: 'Novos negócios',
      operacoes: 'Operações',
      turismo: 'Turismo',
      pd: 'P&D',
      pos_vendas: 'Pós-vendas',
      projetos: 'Projetos',
      producao: 'Produção',
      processos: 'Processos',
      qualidade: 'Qualidade',
      rh_dho: 'RH / DHO',
      relacoes_institucionais: 'Relações institucionais',
      seguranca: 'Segurança',
      servicos_tecnicos: 'Serviços técnicos',
      suprimentos: 'Suprimentos',
      tecnologia_da_informacao: 'Tecnologia da Informação',
      other: 'Outro',
    },
  },

  navigation_menu: {
    assessments: {
      title: 'Avaliações',
    },

    multi_perspective_assessment_individual: {
      title: 'Avaliações de Indivíduos Multi-perspectiva',
    },

    individual_assessments: {
      title: 'Avaliações Individuais',
      self: 'Minhas avaliações',
      others: 'Avaliar pessoas',
    },

    multi_perspective_assessment: {
      title: 'Avaliações Multi-perspectivas',
    },

    respondents: {
      title: 'Respondentes',
    },

    organization_profile: {
      title: 'Perfil da Organização',
    },

    my_profile: {
      title: 'Meu Perfil',
    },

    managers: {
      title: 'Gestores',
    },
  },

  indicators: {
    true: 'Sim',
    false: 'Não',
    uninformed: 'Não informado',
    placeholder: {
      money: 'Ex.: R$1.000.000',
      number: 'Ex.: 10',
      selector: 'Selecione',
      percent: 'Ex.: 100%',
    },
  },

  tabnav: {
    informations: {
      id: 'Informações',
      name: 'Informações',
    },

    indicators: {
      id: 'Indicadores',
      name: 'Indicadores',
    },
  },

  emails: {
    manual: 'Envio manual',
    invite_template: {
      title: 'Email de convite',
      date: 'Envio imediato',
    },
    general_template: {
      title: 'Lembrete Geral',
      date: 'Envio imediato',
    },
    week_before_template: {
      title: 'Lembrete - Semana antes do término',
      date: '1 semana antes do término da avaliação',
    },
    day_before_template: {
      title: 'Lembrete - Véspera do término',
      date: '1 dia antes do término da avaliação',
    },
    results_available_template: {
      title: 'Liberação de resultados',
      date: 'Após o término da avaliação',
    },
    application_email: {
      all: 'Enviar email para todos os respondentes deste avaliado',
      assessed: 'Enviar email para este avaliado',
      unanswered: 'Enviar email para não respondidos deste avaliado',
      role_type_title: {
        all: 'Enviar email para todos os que ainda não responderam?',
        internal: 'Enviar email para todos os stakeholders internos?',
        external: 'Enviar email para todos os stakeholders externos?',
      },
      role_type_body: {
        all: 'Este email será enviado para todos os respondentes cadastrados via email que ainda não concluíram a sua resposta. Após enviar, não é possível desfazer a ação.',
        internal: 'Ao enviar, este email será disparado para todos os respondentes cadastrados via email como stakeholders internos, e não será possível desfazer essa ação',
        external: 'Ao enviar, este email será disparado para todos os respondentes cadastrados via email como stakeholders externos, e não será possível desfazer essa ação.',
      },
    },
    collection_email: {
      all: 'Enviar para todos os avaliados e seus respondentes',
      assessed: 'Enviar para todos os avaliados',
      unanswered: 'Enviar para todos os não respondidos de todos os avaliados',
    },
  },

  progress_bar: {
    text: 'Tempo de {mobile, select, true {resp.} false {respostas}}',
  },

  words: {
    email: 'E-mail',
    email_simple: 'Email',
    password: 'Senha',
    sector: 'Setor',
    gender: 'Gênero',
    company: 'Empresa',
    role: 'Papel',
    your_role: 'Seu papel',
    description: 'Descrição',
    behaviour: 'Comportamento',
    instructions: 'Instruções',
    cancel: 'Cancelar',
    error: 'Erro',
    close: 'Fechar',
    continue: 'Continuar',
    finish: 'Finalizar',
    pause: 'Pausar',
    next: 'Próximo',
    previous: 'Anterior',
    status: 'Status',
    submit: 'Concluir',
    and: 'e',
    minimum: 'Mínimo',
    maximum: 'Máximo',
    minimum_of: 'Mínimo {num}',
    maximum_of: 'Máximo {num}',
    page: 'Página',
    available: 'Disponível',
    unavailable: 'Indisponível',
    of: 'de',
    address: 'Endereço',
    config: 'Configurar',
    release: 'Liberar',
    monitor: 'Acompanhar',
    edit: 'Editar',
    view: 'Visualizar',
    actions: 'Ações',
    required: 'Obrigatório',
    type: 'Tipo',
    questions: 'Questões',
    invited: 'Convidados',
    answered: 'Respondidos',
    not_answered: 'Não Respondido',
    answer: 'Resposta',
    beginning: 'Início',
    end: 'Término',
    duration: 'Duração',
    add: 'Adicionar',
    new: 'Novo',
    name: 'Nome',
    position: 'Função',
    registered: 'Cadastrado',
    pending: 'Pendente',
    manager: 'Gestor',
    owner: 'Gestor Principal',
    hour: 'hora',
    day: 'dia',
    organization: 'Organização',
    individual: 'Indivíduo',
    sex: 'Sexo',
    pcd: 'PcD (Pessoa com Deficiência)',
    color_race: 'Cor / Raça',
    sexual_orientation: 'Orientação Sexual',
    assessed: 'Avaliado',
    assessment: 'Avaliação',
    self_assessment: 'Autoavaliação',
    respondent: 'Respondente',
    respondents: 'Respondentes',
    back_main: 'Retornar para página inicial',
    manual_sending: 'Envio manual',
    date: 'Data',
    sampling: 'Amostra',
    positive: 'Positivo',
    negative: 'Negativo',
    neutral: 'Neutro',
    conclusion: 'Conclusão',
    recommendation: 'Recomendação',
    do_not_apply: 'Não se aplica',
    search: 'Buscar',
    intro: 'Introdução',
    average: 'Média',
    in: 'em',
    general: 'Geral',
    participant: 'Participante',
    terms_of_use: 'Termos de uso',
    privacy_policy: 'Política de privacidade',
    filter: 'Filtrar',
    occupation: 'Atuação',
    assessment_name: 'Nome da Avaliação',
    assessment_status: 'Status Autoavaliação',
    choose: 'Escolha',
    send_all: 'Enviar a todos',
    view_template: 'Visualizar template',
    smallest_score: 'Menor nota',
    biggest_score: 'Maior nota',
    select_all: 'Marcar todos',
    deselect_all: 'Desmarcar todos',
    score: 'Nota',
    meaning: 'Significado',
    legend: 'Legenda',
    population: 'População',
    ideal_sample: 'Amostra ideal',
    minimum_sample: 'Amostra mínima geral',
    general_result: 'Resultado Geral',
    current: 'Atual',
    desired: 'Desejada',
    total: 'Total',
    come_back: 'Voltar',
    start: 'Começar',
    assessed_organization: 'Organização avaliada',
    application: 'Aplicação',
    finished: 'Finalizada',
    initiation: 'Início',
    finale: 'Fim',
    participations: 'Participações',
    reports: 'Relatórios',
    factors: 'Fatores',
    subgroups: 'Subgrupos',

    plurals: {
      day: '{num, plural, one {# dia} other {# dias}}',
      hour: '{num, plural, one {# hora} other {# horas}}',
      answered: '{num, plural, one {Respondido} other {Respondidos}}',
      result: '{num, plural, one {Resultado} other {Resultados}}',
      assessed: '{num, plural, one {Avaliado} other {Avaliados}}',
      available: '{num, plural, one {# avaliação} other {# avaliações}}',
      tag: '{num, plural, one {tag} other {tags}}',
      question: '{num, plural, one {Questão} other {Questões}}',
      alternative: '{num, plural, one {Alternativa} other {Alternativas}}',
      answer: '{num, plural, one {Resposta} other {Respostas}}',
      companies: '{num, plural, one {Empresa} other {Empresas}}',
      people: '{num, plural, one {Pessoa} other {Pessoas}}',
    },

    try_again: 'Tentar novamente',
    delete: 'Excluir',
    confirm: 'Confirmar',
    save: 'Salvar',
    last_name: 'Sobrenome',
    not_informed: 'Não {gender, select, male {informado} female {informada}}',
  },

  checkbox: {
    title: {
      sector: 'Setor',
      role: 'Função',
      cba: 'CBA - Conscious Business Assessment',
      vyl: 'VYL - Valuable Young Leaders',
    },

    label: {
      Administrative: 'Administrativo',
      rh: 'RH',
      attendance: 'Atendimento',
      commercial: 'Comercial',
      self_employed: 'Autônomo',
      specialist: 'Especialista',
      manager: 'Gerente',
      ceo: 'CEO',
      leaderships: 'Lideranças',
      collaborators: 'Colaboradores',
      clients_partners: 'Clientes e parceiros',
      partnerships: 'Sociedade',
      inidividual_assessment: 'Avaliações de Indivíduos',
      organizational_assessment: 'Avaliações de Organizações',
    },
  },

  public_pages: {
    complete_register: {
      title: 'Conte-nos sobre você?',
      subtitle: 'Precisamos de mais alguns dados sobre seu perfil. As informações preenchidas serão utilizadas para fins de pesquisas de maneira agregada, garantindo portanto o seu anonimato.',

      email_label: 'E-mail',
      email_placeholder: 'mariana@email.com',
      i_dont_have_an_email: 'Não possuo um e-mail',
      external_email: 'Resposta através de Link Aberto',

      first_name_label: 'Nome',
      first_name_placeholder: 'Ex.: Mariana',

      last_name_label: 'Sobrenome',
      last_name_placeholder: 'Ex.: Dias',

      working_company_name_label: 'Empresa',
      working_company_name_placeholder: 'Ex.: Humanizadas',

      working_company_department_label: 'Setor',
      working_company_department_placeholder: 'Selecione',

      working_company_job_title_label: 'Função',
      working_company_job_title_placeholder: 'Selecione',

      biological_sex_label: 'Sexo',
      biological_sex_placeholder: 'Selecione',

      gender_label: 'Gênero',
      gender_placeholder: 'Selecione',
      gender_tooltip: 'A identidade de gênero se refere à experiência de uma pessoa com o seu próprio gênero',

      pcd_label: 'PcD (Pessoa com Deficiência)',
      pcd_placeholder: 'Selecione',

      pcd_tooltip_pt1_title: 'Deficiência física',
      pcd_tooltip_pt1_body: 'alteração completa ou parcial de um ou mais segmentos do corpo humano, acarretando o comprometimento da função física, apresentando-se sob a forma de paraplegia, paraparesia, monoplegia, monoparesia, tetraplegia, tetraparesia, triplegia, hemiplegia, hemiparesia, ostomia, amputação ou ausência de membro, paralisia cerebral, nanismo, membros com deformidade congênita ou adquirida, exceto as deformidades estéticas e as que não produzam dificuldades para o desempenho de funções',

      pcd_tooltip_pt2_title: 'Deficiência auditiva',
      pcd_tooltip_pt2_body: 'Perda parcial ou total das possibilidades auditivas sonoras, variando de graus e níveis na forma seguinte: de 41 a 55 db - surdez moderada | de 56 a 70 db - surdez acentuada | de 71 a 90 db - surdez severa | acima de 91 db - surdez profunda | anacusia - perda total',

      pcd_tooltip_pt3_title: 'Deficiência visual',
      pcd_tooltip_pt3_body: 'Deficiência visual caracteriza-se pela limitação ou perda da funções básicas do olho e do sistema visual. O deficiente visual pode ser a pessoa cega ou com baixa visão. A cegueira pressupõe a falta de percepção visual devido a fatores fisiológicos ou neurológicos. A cegueira total ou simplesmente amaurose, caracteriza-se pela completa perda de visão sem percepção visual de luz e forma. A cegueira pode ser congênita ou adquirida. A acuidade visual das pessoas com baixa visão é muito variável; mas, em geral, baixa visão é definida como uma condição na qual a visão da pessoa não pode ser totalmente corrigida por óculos, interferindo em suas atividades diárias, assim como a leitura e a locomoção.',

      pcd_tooltip_pt4_title: 'Deficiência mental',
      pcd_tooltip_pt4_body: 'Funcionamento intelectual significativamente inferior á média, com manifestação antes dos dezoito anos e limitações associadas a duas ou mais áreas de habilidades adaptativas, tais como: comunicação | cuidado pessoal | habilidades sociais | utilização da comunidade | saúde e segurança | habilidades acadêmicas | lazer | trabalho',

      color_label: 'Cor / Raça',
      color_placeholder: 'Selecione',
      color_tooltip: 'Conforme referência do IBGE',

      sexual_orientation_label: 'Orientação Sexual',
      sexual_orientation_placeholder: 'Selecione',
      sexual_orientation_tooltip: 'Orientação diz respeito à atração que se sente por outros indivíduos. Ela geralmente também envolve questões sentimentais, e não somente sexuais. Referência: UFSC',

      cpf_label: 'CPF',
      cpf_placeholder: 'Ex.: 123.456.789-10',
      cpf_tooltip: 'Pedimos o seu CPF para validar sua identidade.',
    },

    questionnaire: {
      warning_dialog: {
        title: 'Vamos lá? Uma vez iniciada, não é possível interromper a avaliação.',
        body: 'Você não poderá pausá-la para finalizar mais tarde. Essa restrição faz parte de nossa metodologia científica de pesquisa.',
        button: 'Responder a avaliação',
        left_button: 'Cancelar',
      },
    },
  },

  pages: {
    login: {
      title: 'Que bom que você voltou!',
      subtitle: 'Para acessar sua conta, insira o email em que recebeu o convite e a senha cadastrada.',
      stay_logged_in: 'Mantenha-me conectado',
    },

    signup: {
      title: 'Estávamos te aguardando!',
      subtitle: 'Já deixamos tudo pronto para você. Para acessar suas avaliações, basta criar uma conta.',
    },

    lastReport: {
      title: 'Aqui está o Relatório Geral da última edição da pesquisa.',
      subtitle: 'Clique no link abaixo para acessar o relatório geral da última edição da Pesquisa Empresas Humanizadas do Brasil.',
      button: 'Acessar relatório',
    },

    thankYouRegister: {
      title: 'Agradecemos pelo seu tempo nos ajudando a coletar essas informações!',
      subtitle: 'Cadastre-se e tenha acesso aos Resultados Gerais da última edição da pesquisa.',
    },

    unauthenticated_contact: {
      title: 'Envie-nos uma mensagem e entraremos em contato.',
      subtitle: 'Conte-nos mais sobre o problema que você encontrou e encontraremos uma solução.',
    },

    password_recovery_enter_email: {
      title: 'Estamos aqui para te ajudar.',
      subtitle: 'Digite seu email de cadastro e te enviaremos um link para criar uma nova senha.',
    },

    password_recovery_reset_password: {
      title: 'Aqui está o seu recomeço.',
      subtitle: 'Crie a sua nova senha e volte a aproveitar todas as vantagens da plataforma.',
    },

    my_profile: {
      title: 'Meu perfil',
      edit_button: 'Editar',
    },

    organization_profile: {
      title: 'Perfil da Organização',
      edit_button: 'Editar',
    },

    support: {
      title: 'Envie-nos uma mensagem e entraremos em contato.',
      subtitle: 'Conte-nos mais sobre o problema que você encontrou e encontraremos uma solução.',
    },

    respondents: {
      title: 'Respondentes',
      title_add: 'Adicionar Respondentes',
      search_field: {
        placeholder: 'Buscar',
      },

      actions: {
        add_sheet: 'Adicionar por planilha',
        download_template: 'Baixar template de planilha',
        upload_sheet: 'Fazer upload de planilha',
        bulk_actions: 'Ações em massa',
      },

      filter: {
        title: 'Filtrar',
      },

      add_button: 'Adicionar',

      no_respondents: {
        message: 'Você ainda não adicionou respondentes.',
        button_text: 'Adicionar agora',
      },

      owner_informations_dialog: {
        label: {
          email: 'Email',
          company: 'Empresa',
          sector: 'Setor',
          role: 'Função',
        },

        close_button: 'Fechar',
      },

      configure_assessment_dialog: {
        title: 'Você deseja configurar uma avaliação agora?',
        body: 'Agora que adicionou novos respondentes à base, escolha um tipo de avaliação para configurar.',

        close_button: 'Fechar',
        config_button: 'Configurar',
      },

      respondents_filter_dialog: {
        title: 'Filtrar Respondentes',

        cancel_button: 'Cancelar',
        aplly_button: 'Aplicar filtros',
      },

      upload_and_download_sheet_dialog: {
        title: 'Lembre-se de usar o template da planilha de upload de respondentes.',
        body: 'Se você ainda não baixou o template, faça o download e adeque a sua planilha para que fique no nosso padrão. Assim, você evitará erros na sua base de respondentes.',

        download_button: 'Baixar template',
        upload_button: 'Fazer upload',
      },

      delete_respondents_dialog: {
        title: 'Excluir {num, plural, one {respondente?} other {respondentes?}}',
        body: 'Ao excluir {num, plural, one {este respondente} other {estes respondentes}}, {num, plural, one {ele não poderá} other {eles não poderão}} mais acessar nenhuma das suas avaliações. Caso {num, plural, one {ele já tenha} other {eles já tenham}} respondido a alguma delas, todos os dados serão excluídos dos cálculos de resultados.',
        body_attention: 'Atenção: essa ação é irreversível.',

        cancel_button: 'Cancelar',
        delete_button: 'Excluir',
      },

      delete_enrollments_dialog: {
        title: 'Excluir {num, plural, one {respondente?} other {respondentes?}}',
        body: 'Ao excluir {num, plural, one {este respondente} other {estes respondentes}}, {num, plural, one {ele não poderá} other {eles não poderão}} mais visualizar a avaliação e deixará de receber os emails referentes a ela. Caso {num, plural, one {ele já tenha} other {eles já tenham}} respondido às perguntas, os dados serão excluídos dos cálculos de resultados.',
        body_attention: 'Atenção: essa ação é irreversível.',

        cancel_button: 'Cancelar',
        delete_button: 'Excluir',
      },

      invitation_dialog: {
        title: 'Convidar para avaliação',
        body: 'Escolha a avaliação para a qual deseja convidar os respondentes que você selecionou.',
        empty: 'Não há avaliações cadastradas em sua organização',
        cancel_button: 'Cancelar',
        invitation_button: 'Convidar',
      },

      edit: {
        title: 'Editar Respondente',
      },

      add: {
        title: 'Adicionar Respondentes',
      },

      add_list: {
        title: 'Adicionar Respondentes a',
        return: 'Retornar à tela de configuração',
        cancel_dialog: {
          title: 'Você deseja sair sem salvar?',
          body: 'Se você sair agora, irá perder todas as informações que preencheu.',
          left_button: 'Sair sem salvar',
          right_button: 'Não sair',
        },
      },

      confirmation_message_dialog: {
        title: 'Muito obrigado!',
        body: 'Agradecemos pelo seu tempo nos ajudando a coletar essas informações.',

        button_finish: 'Finalizar',
        button_check: 'Conferir respostas',
      },
    },

    assessments: {
      finalize_assessment_dialog: {
        title: 'Você deseja finalizar a avaliação?',
        body: 'Finalizando a avaliação, não será possível coletar mais respostas e nem liberá-la novamente. Os resultados serão feitos com base nos dados já recebidos.',
        body_attention: 'Atenção: se você deseja suspender a avaliação durante um período, tente pausá-la.',

        cancel_button: 'Cancelar',
        finalize_button: 'Finalizar',
      },

      send_email_dialog: {
        title: 'Enviar email para {num, plural, one {# respondente} other {# respondentes}}?',
        body: 'Ao enviar, este email será disparado e não será possível desfazer essa ação. Para enviar um email para apenas alguns respondentes, selecione-os na tabela abaixo e clique em “Ações em massa”.',

        cancel_button: 'Cancelar',
        everyone_button: 'Enviar para todos',
      },

      reminder_email_dialog: {
        title: 'Enviar email',
        body: 'Escolha o email que deseja enviar para {num, plural, one {o respondente selecionado} other {os respondentes selecionados}}. Ao enviar, este email será disparado e não será possível desfazer essa ação.',

        cancel_button: 'Cancelar',
        everyone_button: 'Enviar para todos',
      },
    },

    organizational_assessments: {
      title: 'Avaliações Individuais',

      answered_tooltip: 'Estamos calculando os resultados!',

      read_only_dialog: {
        title: 'Você já respondeu a essa avaliação.',
        message: 'Nenhum campo poderá ser alterado. Você deseja visualizar a resposta enviada?',
        submit_button: 'Ver resposta',
      },

      no_invites: {
        message: 'Você ainda não foi convidado a responder nenhuma avaliação de organizações.',
        button_text: 'Preciso de ajuda',
      },

      empty_search: {
        message: 'Não foi encontrado nenhum resultado para a sua busca. Experimente os filtros para mais opções.',
      },
    },

    config_assessments: {
      success_dialog: {
        title: 'Avaliação configurada e pronta para liberar!',
        body: 'Esta avaliação estará agora disponível no menu “Liberar”. Ela será iniciada automaticamente na data de início programada, e poderá ser editada até lá.',
        button_text: 'Concluir',
      },

      success_dialog_2: {
        title: 'Questionário configurado com sucesso!',
        body: 'Após configurar todos os questionários, a avaliação estará agora disponível no menu “Liberar”. Ela será iniciada automaticamente na data de início programada, e poderá ser editada até lá.',
        button_text: 'Ok',
      },

      cancel_dialog: {
        title: 'Você deseja sair sem salvar?',
        body: 'Se você sair agora, irá perder todas as informações que preencheu.',
        left_button: 'Sair sem salvar',
        right_button: 'Não sair',
      },

      release_dialog: {
        title: 'Liberar avaliação sem salvar as edições recentes?',
        body: 'Se você deseja salvar as edições antes de liberar a avaliação, clique em “não liberar” abaixo e salve as configurações antes de prosseguir.',
        left_button: 'Liberar sem salvar',
        right_button: 'Não liberar',
      },

      template_dialog: {
        use_template: 'Enviar este email para os respondentes?',
        send_date: 'Data de envio',
        subject: 'Assunto da mensagem',
        body: 'Corpo da mensagem',
      },

      save_config: 'Salvar',
      email_section: 'Envio de emails',
      email_instructions: 'Selecione quais templates de email você irá enviar para os respondentes convidados durante o curso da sua avaliação.',
      start_date: 'Data de início',
      end_date: 'Data de término',
      link: 'https://app.humanizadas.com/avaliacoes/',
      link_title: 'Link de compartilhamento para {name}',
      copy_link: 'Copiar link',

      invite_template: {
        title: 'Email de convite',
        date: 'Envio imediato',
      },
      general_template: {
        title: 'Lembrete geral',
        date: 'Envio imediato',
      },
      week_before_template: {
        title: 'Lembrete - Semana antes do término',
        date: '1 semana antes do término da avaliação',
      },
      day_before_template: {
        title: 'Lembrete - Véspera do término',
        date: '1 dia antes do término da avaliação',
      },
      results_available_template: {
        title: 'Liberação de resultados',
        date: 'Após o término da avaliação',
      },

      header_items: {
        role: 'Papel dos resp.',
      },

      header_menu: {
        release: 'Liberar agora',
        play: 'Retomar',
        pause: 'Pausar',
        finish: 'Finalizar agora',
        results: 'Ver resultado',
        view: 'Visualizar questionários',
        answer_self_assessment: 'Responder autoavaliação',
      },

      respondents_section: 'Respondentes',
      respondents_instructions: 'Além dos respondentes adicionados por esta área, você poderá convidar também outras pessoas para responder à sua avaliação através de um link - que será disponibilizado após a liberação da avaliação.',
      empty_table: 'Ainda não foi disponibilizado nenhum template para a sua organização.',
    },

    see_all_companies: {
      company_dialog: {
        general_public_respondents: 'Respondentes (público geral)',
        specific_public_respondents: 'Respondentes (Públicos específicos)',
        specific_public: 'Públicos específicos',
      },
      organization_table_title: 'Todas as Avaliações de Organizações',
      individual_table_title: 'Todas as Avaliações de Indivíduos',
      self_assessment_table_title: 'Todas as Autoavaliações',
    },

    individual_assessments: {
      title: 'Avaliações de Indivíduos',
      manager: {
        email: {
          manual: 'Envio manual',
          assessed_respondents: 'Enviar para todos os avaliados e seus respondentes',
          assessed: 'Enviar para todos os avaliados',
          not_answered_assessed: 'Enviar para todos os não respondidos de todos os avaliados',
        },
        invite: {
          warning: 'Marque a caixa à esquerda do nome de cada respondente para convidá-lo.',
          respondents: 'Além dos respondentes adicionados por esta área, você poderá convidar também outras pessoas para responder à sua avaliação através de um link aberto - que será disponibilizado após a liberação da avaliação.',
        },
      },
      title_self: 'Minhas avaliações',
      title_others: 'Avaliar pessoas',

      read_only_dialog: {
        title: 'Você já respondeu a essa avaliação.',
        message: 'Nenhum campo poderá ser alterado. Você deseja visualizar a resposta enviada?',
        submit_button: 'Ver resposta',
      },

      no_invites: {
        message: 'Você ainda não foi convidado a responder nenhuma avaliação de indivíduos.',
        button_text: 'Preciso de ajuda',
      },

      empty_search: {
        message: 'Não foi encontrado nenhum resultado para a sua busca. Experimente os filtros para mais opções.',
      },
    },

    config_individual_assessments: {
      title: 'Configurar Avaliações Indivíduos',
      confirm_button: 'Concluir Configurações',
      dialog_complete: {
        title: 'Avaliação configurada e pronta para liberar!',
        body: 'Esta avaliação estará agora disponível no menu “Liberar”. Ela será iniciada automaticamente na data de início programada, e poderá ser editada até lá.',
        button: 'Concluir',
      },
      dialog_incomplete: {
        title: 'Concluir configuração sem preencher todos os avaliados disponíveis?',
        body: 'O restante das avaliações que você adquiriu continuarão no menu “Configurar” para que sejam configuradas e liberadas posteriormente.',
        left_button: 'Continuar configurando',
        right_button: 'Concluir',
      },
      dialog_add: {
        title: 'Adicionar novo usuário',
        title_edit: 'Editar usuário',
        subtitle: 'Este usuário ainda não está cadastrado. Para convidá-lo como avaliado em uma avaliação, preencha seus dados abaixo.',
        email_label: 'Email do avaliado',
      },
      info: {
        title: 'Configurações gerais da {type, plural, one {Autoavaliação} other {Avaliação #}} - {title}',
        date_title: 'Início e Término',
        date_subtitle: 'As datas de início e término dessa avaliação serão as mesmas para todos os avaliados configurados abaixo.',
        start_date: 'Data de início',
        end_date: 'Data de término',
      },
      enrollments: {
        title: 'Configurar {type, plural, one {Autoavaliação} other {Avaliação #}} - {title}',
      },
      details: {
        email: 'Email do avaliado',
        warning: 'Marque a caixa à esquerda do nome de cada respondente para convidá-lo.',
        respondents: 'Além dos respondentes adicionados por esta área, você poderá convidar também outras pessoas para responder à sua avaliação através de um link aberto - que será disponibilizado após a liberação da avaliação.',
      },
    },

    release_individual_assessments: {
      title: 'Liberar Avaliações de Indivíduos',

      assessment_dialog: {
        title: 'Liberar avaliação agora?',
        body: 'Se você liberar a avaliação agora, ela será enviada para todos os respondentes. Essa ação não pode ser desfeita.',
        release_button: 'Liberar',
        wait_button: 'Aguardar data de início',
      },

      success_save_changes_dialog: {
        title: 'Edições salvas com sucesso!',
        body: 'Esta avaliação se iniciará automaticamente na data de início programada, e pode ser editada até lá.',
        conclude_button: 'Concluir',
      },

      release_assessed: {
        title: 'Liberar {type, plural, one {Autoavaliação} other {Avaliação #}} - {title}',
        email_label: 'Email do avaliado',
      },
    },

    monitor_individual_assessments: {
      title: 'Acompanhar Avaliações de Indivíduos',

      finish_dialog: {
        title: 'Você deseja finalizar a avaliação?',
        body: 'Finalizando a avaliação, não será possível coletar mais respostas e nem liberá-la novamente. Os resultados serão feitos com base nos dados já recebidos.',
      },

      pause_dialog: {
        title: 'Você deseja pausar a avaliação?',
        body: 'Enquanto a avaliação estiver pausada, os respondentes não poderão vê-la e nem enviar respostas. Você poderá retomá-la novamente a qualquer momento.',
        warn: 'Atenção: a data de término não será alterada.',
      },

      invite: {
        all: 'Enviar para todos os avaliados e seus respondentes',
        assessed: 'Enviar para todos os avaliados',
        not_answered: 'Enviar para todos os não respondidos de todos os avaliados',
      },

      assessed_invite: {
        all: 'Enviar email para todos os respondentes deste avaliado',
        assessed: 'Enviar email para este avaliado',
        not_answered: 'Enviar email para não respondidos deste avaliado',
      },

      general: {
        title: 'Acompanhar {type, plural, one {Autoavaliação} other {Avaliação #}} - {title}',
        date_save_button: 'Salvar data',
      },

      assessed_user: {
        status: 'Status da autoavaliação',
      },
    },

    release_assessments: {
      table_title: 'Liberar Avaliações',
      table_empty: 'Você ainda não possui nenhuma avaliação configurada para liberar.',
    },

    monitor_assessments: {
      table_title: 'Acompanhar Avaliações',
      table_empty: 'Você ainda não possui nenhuma avaliação liberada para acompanhar.',
    },

    organizational_assessments_manager: {
      title: 'Configurar Avaliações',

      read_only_dialog: {
        title: 'Você já respondeu a essa avaliação.',
        message: 'Nenhum campo poderá ser alterado. Você deseja visualizar a resposta enviada?',
        submit_button: 'Ver resposta',
      },

      empty_table: 'Ainda não foi disponibilizado nenhum template para a sua organização.',

      no_invites: {
        message: 'Você ainda não foi convidado a responder nenhuma avaliação de indivíduos.',
        button_text: 'Preciso de ajuda',
      },

      empty_search: {
        message: 'Não foi encontrado nenhum resultado para a sua busca. Experimente os filtros para mais opções.',
      },
    },

    questionnaire: {
      progress: 'Parte {start} de {end}',
      required_fields: 'Campos obrigatórios são marcados com um *',
      back_to_main_page: 'Retornar para página inicial',
      answer_all_required: 'Por favor, preencha todos os campos obrigatórios',
      matrix_error: 'Escolha ao menos {min} itens',
      assessment_description: 'Descrição da avaliação',
      exit_view: 'Sair da Visualização',

      warning_dialog: {
        title: 'Conclusão',
        message: 'Para concluir a avaliação clique em enviar respostas, ou clique em conferir caso queira editar suas respostas.',
        cancel_button: 'Conferir respostas',
      },

      success_dialog: {
        title: 'Muito obrigado!',
        message: 'Agradecemos pelo seu tempo nos ajudando a coletar essas informações.',
      },
    },

    managers: {
      confirm_demote_dialog: {
        title: 'Poxa! Você deixou de ser o Gestor Principal da empresa.',
        body: 'Você não poderá mais editar os Usuários e nem o Perfil da organização.',
      },

      confirm_promote_dialog: {
        title: 'Transferir atuação de Gestor Principal para {name}',
        body: 'Ao tornar outro usuário o Gestor Principal, você perderá este papel. Somente o Gestor Principal pode editar os Gestores e o Perfil da Organização.',

        button_submit: 'Tornar Gestor Principal',
      },

      confirm_destroy_dialog: {
        title: 'Excluir Gestor',
        body: 'Ao excluir este Gestor, ele não poderá mais acessar a plataforma da sua organização nem gerenciar avaliações  e respondentes.',
      },

      add_manager_dialog: {
        title: 'Adicionar Gestor',
        body: 'Para adicionar um Gestor, é preciso que ele(a) se cadastre pelo link abaixo:',
      },

      user_not_found_dialog: {
        title: 'Ops... Não encontramos nenhum cadastro com este email.',
        body: 'Certifique-se de que a pessoa que você quer convidar já esteja cadastrada e confira se digitou o email corretamente.',
      },

      table: {
        title: 'Gestores',
        empty_table: 'Não foi encontrado nenhum gestor.',
      },

      dialog: {
        email: 'E-mail',
        company: 'Empresa',
        sector: 'Setor',
        position: 'Função',
      },
    },

    messages: {
      thank_you: {
        title: 'Muito Obrigado!',
        subtitle: 'Agradecemos pelo seu tempo nos ajudando a coletar essas informações.',
        alt_desktop: 'A imagem mostra duas pessoas em frente a um computador',
        alt_mobile: 'A imagem mostra duas pessoas em frente a um celular',
        subtitle_desktop: 'Ilustração criada por pikisuperstar',
        subtitle_mobile: 'Ilustração criada por pikisuperstar & pch.vector',
      },
      thank_you_public: {
        title: 'Muito Obrigado!',
        subtitle: 'Agradecemos pelo seu tempo nos ajudando a coletar essas informações.',
        subtitle_2: 'Caso deseje aproveitar ainda mais a experiência da Plataforma Humanizadas acesse sua conta por meio de um computador.',
        alt_desktop: 'A imagem mostra duas pessoas em frente a um computador',
        alt_mobile: 'A imagem mostra duas pessoas em frente a um computador',
        subtitle_desktop: 'Ilustração criada por pikisuperstar',
        subtitle_mobile: 'Ilustração criada por pikisuperstar & pch.vector',
      },
      assessment_expired: {
        title: 'Pesquisa encerrada',
        subtitle: 'O tempo de resposta dessa avaliação já se encerrou. Agradecemos pelo seu interesse!',
        alt_desktop: 'A imagem mostra uma pessoa em frente a um computador',
        alt_mobile: 'A imagem mostra uma pessoa em frente a um computador',
        subtitle_desktop: 'Ilustração criada por pikisuperstar',
        subtitle_mobile: 'Ilustração criada por pikisuperstar & pch.vector',
      },
      assessment_not_started: {
        title: 'Avaliação não iniciada.',
        subtitle: 'Essa avaliação ainda não está recebendo respostas, verifique com quem lhe encaminhou esse link qual a data de início correta. Esperamos lhe entregar uma excelente experiência. Agradecemos pelo seu tempo!',
        alt_desktop: 'A imagem mostra uma pessoa em frente a um computador',
        alt_mobile: 'A imagem mostra uma pessoa em frente a um computador',
        subtitle_desktop: 'Ilustração criada por pikisuperstar',
        subtitle_mobile: 'Ilustração criada por pikisuperstar & pch.vector',
      },
      mobile_not_available: {
        title: 'Oops...',
        subtitle: 'Desculpe-nos o transtorno. Ainda estamos construindo a experiência Humanizadas para você acessar via smartphone. Por enquanto, você poderá acessar a plataforma somente no seu computador.',
        alt_desktop: 'A imagem mostra duas pessoas em frente a um computador',
        alt_mobile: 'A imagem mostra duas pessoas em frente a um celular',
        subtitle_desktop: 'Ilustração criada por pikisuperstar',
        subtitle_mobile: 'Ilustração criada por pikisuperstar & pch.vector',
      },
      error: {
        title: 'Erro',
        subtitle: 'Página não encontrada.',
        errorMessage: 'Sentimos muito, mas algo deu errado durante a sua navegação. Tente uma das alternativas abaixo.',
        alt_desktop: 'A imagem mostra duas pessoas em frente a um computador',
        alt_mobile: 'A imagem mostra duas pessoas em frente a um computador',
        subtitle_desktop: 'Ilustração criada por pikisuperstar',
        subtitle_mobile: 'Ilustração criada por pikisuperstar & pch.vector',
      },
      invalid_link: {
        title: 'Link inválido',
        subtitle: 'Se você está aqui é porque recebeu um link único de outra pessoa.',
        body: 'Por favor, verifique se recebeu um e-mail de cadastro em sua caixa de e-mail (incluindo spam) e, caso não tenha recebido, entre em contato conosco através do',
        email: 'suporte@humanizadas.com',
        alt_desktop: 'A imagem mostra uma pessoa em frente a um computador',
        alt_mobile: 'A imagem mostra uma pessoa em frente a um computador',
        subtitle_desktop: 'Ilustração criada por pikisuperstar',
        subtitle_mobile: 'Ilustração criada por pikisuperstar & pch.vector',
      },
    },

    terms_confirmation: {
      title: 'Notificação de Privacidade',
      subtitle: 'Olá, atualizamos a Política de Privacidade e os Termos de Uso da nossa plataforma para trazer mais transparência para você sobre como tratamos os seus dados. Por isso, para continuar utilizando nossa plataforma precisamos que você leia e aceite os termos abaixo:',
      confirm_button: 'Eu li e concordo',
    },

    monitor_assessments_page: {
      title: 'Acompanhar',
      respondents_section: 'Respondentes',
      header_menu: {
        answer_self_assessment: 'Responder autoavaliação',
      },
    },

    dashboard: {
      tables: {
        assessed_invite: {
          all: 'Enviar email para todos os respondentes {num, plural, one {deste avaliado} other {destes avaliados}}',
          assessed: 'Enviar email para {num, plural, one {este avaliado} other {estes avaliados}}',
          not_answered: 'Enviar email para não respondidos deste avaliado',
        },
        evaluators_link: 'Link avaliadores',
        assessed_link: 'Link avaliados',
        internal_link: 'Avaliadores Internos',
        external_link: 'Avaliadores Externos',
      },
      end_application: 'Para o fim da aplicação',
      send_reminder: 'Enviar lembrete',
      higher_answers: 'N° de respostas superior à amostra',
      lower_answers: 'N° de respostas inferior à amostra',
      absolute_number_answers: 'Respostas (números absolutos)',
      desired_sample: '{answers, plural, one {# resposta} other {# respostas}} /nacima da amostra desejada',
      achieve_ideal_sample: '{answers, plural, one {Falta # resposta} other {Faltam # respostas}} /npara atingir a amostra ideal',
      email_reminder: 'Emails e lembretes',
      completed_assessments: 'Avaliações concluídas',
      see_all: 'Ver todos',
      number_of_answers: 'Nº de Respostas',
      header: {
        play_and_pause: '{isPaused, select, true {Retomar} false {Pausar}} aplicação',
        view_reports: 'Ver Relatórios',
        view_group_reports: 'Ver Resultados do Grupo',
        export_reports: 'Exportar Relatório',
        share_reports: 'Compartilhar Resultados',
        view_questionnaire: 'Visualizar questionário',
        finish_application: 'Finalizar aplicação',
        share_link: 'Link de compartilhamento',
        internal_stakeholders: 'Stakeholders internos',
        external_stakeholders: 'Stakeholders externos',
        response_rate: 'Taxa de Respostas',
        calculate_results: 'Calcular resultados',
        results_being_calculate: 'Os resultados estão sendo calculados',
      },
      line_graph: {
        answers_per_day: 'Respostas por dia',
        total_answers: 'Respostas totais',
        number_of_responses: 'Nº de Respostas',
      },
      reminder: {
        automatic_emails: 'Emails automáticos',
        email_results_appraised: 'Email com Resultados para Avaliados',
        send_email_to_participants: 'Envie um email (pré-definido) para os participantes dessa avaliação',
        send_to_all_unanswered: 'Enviar para todos os não respondidos',
        send_email_to_appraised: 'Envie um email (pré-definido) disponibilizando os resultados para os avaliados',
        send_results_to_all_appraised: 'Enviar Resultados para todos os avaliados',
        send_all_internal_stakeholders: 'Enviar para todos os Stakeholders Internos',
        send_all_external_stakeholders: 'Enviar para todos os Stakeholders Externos',
      },
      individual: {
        conclusion_rate_summary: 'Resumo da Taxa de Conclusão',
        total_number_answers: 'Porcentagem atingida',
        ideal_sample_reached: 'da Amostra Ideal atingida',
        desired_sample_reached: 'da Amostra Desejada atingida',
        minimum_sample_reached: 'da Amostra Miníma Geral atingida',
        engagement_by_appraisees: 'Engajamento por Avaliados',
        bigger_engagement: 'Maior engajamento',
        less_engagement: 'Menor engajamento',
        multiperspective_assessment: 'Avaliação de Multiperspectiva',
      },
      organization: {
        absolute_number_responses: 'N° absoluto de respostas',
        organization_assessment: 'Avaliação de Organizações',
      },
      self_assessment: {
        last_answers: 'Últimas Respostas',
        individual_self_assessment: 'Autoavaliação Individual',
      },
    },

    reports: {
      yours_answers: 'Suas respostas',
      global_average: 'Média geral: {value}',
      your_result: 'Seu resultado',
      answered_average: 'Média dos respondidos',
      less_developed: 'Menos desenvolvidos',
      more_developed: 'Mais desenvolvidos',
      smallest_result: 'Menor resultado: {value}',
      biggest_result: 'Maior resultado: {value}',

      competences_title: 'Competências',
      charts_title: 'Gráficos',
      behavior_title: 'Expoentes comportamentais',
      development_title: 'Dicas de desenvolvimento',

      report_360: {
        title: 'Resultado de {type, plural, one {Autoavaliação} other {Avaliação #}} - {title}',
        valued_questions_title: 'Médias por comportamento',
        text_questions_title: 'Respostas Abertas',
      },

      skeleton: {
        title: 'Descrição da avaliação',
        description: 'Você está participando da Segunda Edição da Pesquisa Empresas Humanizadas Brasil 2020. Ela tem como objetivo identificar práticas humanizadas presentes dentro das Organizações por meio de um processo de escuta de todas as partes envolvidas. Sua contribuição é muito importante para que a empresa possa identificar como lhe servir melhor e também a Sociedade.',
      },
    },

    respondent_role: {
      title_individual: 'Olá, seja bem-vindo(a), você foi convidado(a) a responder a avaliação de',
      title_organization: 'Olá, seja bem-vindo(a), você foi convidado(a) a responder a avaliação da organização',
      subtitle_organization: 'Selecione abaixo o seu relacionamento com a organização avaliada.',
      subtitle_individual: 'Selecione abaixo qual é o seu relacionamento com {value}.',
    },
  },

  reports: {
    cba_cla: {
      tables: {
        total_group: 'Grupo total',
        completed_answers: 'Respostas completas',
        group_and_self_legend: 'Visão do Grupo e Autoavaliação',
        potentially_limiting: 'Potencialmente limitante',
      },
      graphs: {
        labels: {
          bigger: 'Maior valor',
          median: 'Mediana',
          smaller: 'Menor valor',
          gravity_center: 'CGC Atual',
        },
        group_legend: 'Visão do Grupo',
        axis: {
          very_high: 'Muito alta',
          high: 'Alta',
          medium: 'Mediana',
          low: 'Baixa',
          very_low: 'Muito baixa',
          stage_replace: 'Estágio {number}',
        },
      },
      recommendation_analysis: 'Recomendações para o processo de análise',
      individual_development: 'Plano de Desenvolvimento Individual',
      considerations: 'Considerações',
      recommendation: 'Recomendações',
      respondents_section: {
        reliability: 'Confiabilidade',
        applications: 'Aplicações',
      },
      cards_section: {
        group_view: 'Visão de Grupo',
        self_assessment: 'Autoavaliação',
        benchmark: 'Benchmark',
        influence: 'Influência',
        stage: 'Estágio',
      },
      one_page: {
        top_3_card_title: 'Top 3 | Cultura Organizacional',
      },
      graph_interpretation: {
        center: {
          bar_1: 'Sociedade',
          bar_2: 'Parceiros',
          bar_3: 'Clientes',
          bar_4: 'Colaboradores',
          bar_5: 'Lideranças',
          bar_6: 'Geral',
        },
        radar: {
          bar_1: 'Criação',
          bar_2: 'Realização',
          bar_3: 'Organização',
          bar_4: 'Colaboração',
          label_1: 'Experiência atual',
          label_2: 'Experiência desejada',
        },
      },
      labels: {
        difference: 'Diferença',
        benchmark: 'Benchmark',
        trust_level: 'Nível de confiança',
        perspective: 'Perspectiva Público {isInternal, select, true {Interno} false {Externo}}',
        meaning: 'Significado',
        score: 'Score',
        ratings_scale: 'Escala de ratings',
        level: 'Nível',
      },
      vertical_levels: {
        level_1: {
          title: 'Sobrevivência',
          description: 'foco na proteção e nas necessidades básicas da organização.<br/>Pode ocorrer manipulação, coerção, baixo nível de confiança e engajamento.',
          text: 'Autoproteção e necessidade de curto prazo.',
        },
        level_2: {
          title: 'Processos',
          description: 'foco na conformidade e estabilidade dos processos e relações.<br/>As regras e os padrões de trabalho governam o comportamento e atitude das pessoas.',
          text: 'Manutenção das normas e padrões vigentes,',
        },
        level_3: {
          title: 'Especialização',
          description: 'foco na especialização, nas competências e eficiência.<br/>As habilidades e as áreas de interesse dos indivíduos direcionam os comportamentos.',
          text: 'Especialização do processo e eficiência.',
        },
        level_4: {
          title: 'Resultados',
          description: 'foco nas análises, nos resultados e nas metas a serem atingidas.<br/>Alto nível de responsabilização e forte senso de meritocracia dominam as relações.',
          text: 'Entrega de resultados e efetividade.',
        },
        level_5: {
          title: 'Pessoas',
          description: 'foco na relações humanas e na interação com o sistema.<br/>A perspectiva de competição é instituída pela abundância e interdependência sistêmica.',
          text: 'Relações humanas e com o meio ambiente',
        },
        level_6: {
          title: 'Co-criação',
          description: 'foco na capacidade de integração e transformação sistêmica.<br/>As divergências passam a ser pontos de apoio para co-criar e co-inovar.',
          text: 'Propósito elevado e gestão dinâmica',
        },
        level_7: {
          title: 'Propósito',
          description: 'foco na sabedoria e na inteligência coletiva. Capacidade de sustentar tensões como complexidade e simplicidade, integridade e fractal.',
          text: 'A serviço do bem-estar da Humanidade',
        },
      },
      horizontal_levels: {
        level_1: {
          title: 'Perfeccionista',
          description: 'fazer a coisa certa, evitar enganos e trabalhar em busca de auto-desenvolvimento e autocontrole.',
        },
        level_2: {
          title: 'Prestativa',
          description: 'ser útil e confiável, estar disponível para as pessoas, antecipar e prover as necessidades dos outros.',
        },
        level_3: {
          title: 'Realizadora',
          description: 'conquistar metas, apresentar uma imagem que apoie o sucesso da organização, ser produtivo e performar.',
        },
        level_4: {
          title: 'Criativa',
          description: 'ser autêntico e expressar individualidade, ser conectado e verdadeiro com as emoções, ter significado e fazer a diferença no mundo.',
        },
        level_5: {
          title: 'Observadora',
          description: 'dar sentido ao mundo, economizar recursos, pensar, analisar, resolver, evitar dependência e perseguir o conhecimento.',
        },
        level_6: {
          title: 'Questionadora',
          description: 'criar estabilidade, confiança e segurança, proteger a organização, evitar ameaças e riscos, ser leal e responsável.',
        },
        level_7: {
          title: 'Entusiasta',
          description: 'sempre com uma perspectiva positiva, foco no futuro, perseguir prazer, liberdade e ampliar as possibilidades da vida.',
        },
        level_8: {
          title: 'Protetora',
          description: 'ser forte e controlar, evitar a fraqueza e vulnerabilidade, fazer as coisas acontecerem, tomar decisões e atingir resultados.',
        },
        level_9: {
          title: 'Pacificadora',
          description: 'promover harmonia e paz, receber influência das pessoas, criar conforto e rotinas, acolher e reduzir conflitos no grupo.',
        },
      },
    },
    cba: {
      title: 'CBA® One Page',
      error_margin: 'Margem de erro',
      confidence: 'Grau de confiança',
      print_button: 'Salvar PDF',

      engagement_section: 'Engajamento geral dos stakeholders',

      organizational_identity: {
        title: 'Identidade organizacional',
        vertical_development: 'Desenvolvimento Vertical (Atual)',
        horizontal_development: 'Desenvolvimento Horizontal (Atual)',
        values_to_develop: 'Top 3 Valores para Desenvolver',
        values_to_adjust_reframe: 'Top 3 Valores para Ajustar ou Ressignificar',
      },

      stakeholders_engagement: {
        title: 'Engajamento dos stakeholders',
        description: 'Resultado do grau de engajamento dos stakeholders do negócio (pontuação de -100 a +100). Pontuação negativa expressa desengajamento, e pontuação positiva expressa engajamento.',
      },

      stakeholders_management: {
        title: 'Princípios de gestão',
        description: 'Resultado da avaliação das práticas e dos resultados de gestão em cinco princípios (pontuação de 0 a 100).',
      },

      scores_scale: {
        focus: 'Foco',
        meaning: 'Significado',
        min_max: '{min} a {max}',

        quality: {
          title: 'Qualidade das relações',
          description: 'Índice de Qualidade das Relações (IQR)',
          '0_50': 'Relações extremamente deterioradas, colocando em risco o futuro do negócio.',
          '50_60': 'Relações com alto grau de criticidade, exigindo mudanças em caráter de urgência.',
          '60_70': 'Relações com problemas graves exigindo intervenções imediatas com as lideranças.',
          '70_80': 'Relações apresentam problemas críticos exigindo intervenções em sistemas internos.',
          '80_90': 'Relações com problemas pontuais exigindo atenção imediata para não se deteriorar.',
          '90_95': 'Relações positivas e bastante saudáveis.',
          '95_100': 'Relações extremamente positivas e saudáveis.',
        },

        maturity: {
          title: 'Maturidade organizacional',
          description: 'Centro de Gravidade de Consciência (CGC)',
          value1: 'Autoproteção e necessidades de curto prazo.',
          value2: 'Manutenção das normas e padrões vigentes.',
          value3: 'Especialização dos processos e eficiência.',
          value4: 'Entrega de resultados e efetividade.',
          value5: 'Relações humanas e com o meio ambiente.',
          value6: 'Propósito elevado e gestão dinâmica.',
          value7: 'A serviço do bem-estar da Humanidade.',
        },
      },

      engagement_messages: {
        '-100_-50': 'Na percepção dos stakeholders, a organização expressa um nível péssimo de engajamento com problemas que colocam em risco o futuro do negócio.',
        '-49_-1': 'Na percepção dos respondentes a organização expressa um nível positivo de engajamento, tendo relações com problemas pontuais exigindo atenção imediata para não se deteriorar.',
        '0_24': 'Na percepção dos stakeholders, a organização expressa um nível baixo de engajamento com problemas críticos que demandam atenção.',
        '25_49': 'Na percepção dos stakeholders, a organização expressa um nível bom de engajamento com problemas pontuais que demandam atenção.',
        '50_64': 'Na percepção dos stakeholders, a organização expressa um alto nível de engajamento com várias oportunidades para melhorias que devem ser exploradas.',
        '65_74': 'Na percepção dos stakeholders, a organização expressa um alto nível de engajamento com presença de oportunidades para melhorias.',
        '75_84': 'Na percepção dos stakeholders, a organização expressa um nível muito alto de engajamento e deve atuar para que continue assim.',
        '85_100': 'Na percepção dos stakeholders, a organização expressa um nível excelente de engajamento e provavelmente continuará assim no futuro.',
      },
    },
    focus: {
      header: {
        dimension: 'Dimensão',
        score: 'Score',
        scale: 'Escala',
      },
    },
    letter: {
      name: 'Pedro Ernesto Paro',
      role: 'CEO e fundador',
    },
    iqr_section: {
      levels: {
        '95_100': 'Relações extremamente positivas e saudáveis.',
        '90_95': 'Relações positivas e bastante saudáveis.',
        '80_90': 'Relações com problemas pontuais exigindo atenção imediata para não se deteriorar.',
        '70_80': 'Relações apresentam problemas críticos exigindo intervenções em sistemas internos.',
        '60_70': 'Relações com problemas graves exigindo intervenções imediatas com as lideranças.',
        '50_60': 'Relações com alto grau de criticidade, exigindo mudanças em caráter de urgência.',
        '0_50': 'Relações extremamente deterioradas, colocando em risco o futuro do negócio.',
      },
      labels: {
        '95_100': '95 a 100',
        '90_95': '90 a 95',
        '80_90': '80 a 90',
        '70_80': '70 a 80',
        '60_70': '60 a 70',
        '50_60': '50 a 60',
        '0_50': '0 a 50',
      },
    },
    organization_identity: {
      image_alt: 'gráfico de identidade organizacional',
    },
    narratives_analysis: {
      internal: 'Narrativas internas',
      external: 'Narrativas externas',
      title: 'As narrativas revelam...',
      levels: {
        level100: 'Relações extremamente positivas à serviço da Humanidade e da Vida.',
        level80: 'Relações saudáveis com problemas pontuais exigindo atenção.',
        level60: 'Relações saudáveis com problemas críticos exigindo intervenções.',
        level40: 'Relações com problemas graves exigindo intervenções imediatas com as lideranças.',
        level20: 'Relações com alto grau de criticidade, exigindo mudanças em caráter de urgência.',
        level0: 'Relações com alto grau de criticidade, exigindo mudanças em caráter de urgência.',
      },
    },
    archetypes_table: {
      cultural_orientation: 'Orientação Cultural',
    },
    components: {
      consolidated_results: {
        score: 'Score',
        sample_size: 'Tamanho amostra',
        margin_of_error: 'Margem de erro',
        trust_rating: 'Grau de confiança',
      },
      consolidated_levels: {
        very_high: 'Extremamente alto',
        high: 'Alto',
        middle: 'Mediano',
        low: 'Baixo',
        extremely_low: 'Extremamente baixo',
      },
      time_circles: {
        past: 'Passado',
        present: 'Presente',
        future: 'Futuro',
      },
      base_page: {
        answered_by: 'Respondido por',
      },
      center_chart: {
        optimistic_outlook: 'Perspectiva otimista',
        pessimistic_outlook: 'Perspectiva pessimista',
      },
      start_chart: {
        degree_of_maturity: 'Grau de maturidade organizacional',
      },
      multiple_bars_chart: {
        center_of_gravity: 'Centro de Gravidade',
      },
      side_menu: {
        menu_intro: 'Introdução',
        letter: 'Carta ao leitor',
        intro_comments: 'Comentários gerais',
        menu_rating: 'Rating',
        intro: 'Introdução aos ratings',
        general_results: 'Resultado geral',
        general_comments: 'Comentários gerais',
        focus: 'Onde focar',
        menu_perspective: 'Perspectiva multi-stakeholders',
        respondents: 'Respondentes',
        engagement: 'Engajamento',
        experience: 'Experiência',
        score: 'Satisfação',
        perspective: 'Perspectiva de futuro',
        menu_principles: 'Princípios',
        overview: 'Visão geral dos princípios',
        purpose: 'Propósito maior',
        strategy: 'Estratégia de valor',
        conscious_culture: 'Cultura consciente',
        adaptability: 'Adaptabilidade evolutiva',
        leadership: 'Liderança consciente',
        menu_values: 'Valores',
        menu_identity: 'Identidade',
        formation: 'Formação de identidade',
        vertical_dev: 'Desenvolvimento vertical',
        horizontal_dev: 'Desenvolvimento horizontal',
        quality: 'Índice de qualidade das relações',
        menu_narratives: 'Narrativas',
        map: 'Mapa de narrativas',
        main: 'Principais narrativas',
        analysis: 'Análise de conteúdo ',
        highlights: 'Práticas de destaque',
        recommended: 'Práticas recomendadas',
        demographics: 'Dados demográficos',
        reputation: 'Reputação',
        general_reputation: 'Reputação geral',
        reputation_by_stakeholder: 'Reputação por Stakeholder',
        responding_data: 'Dados respondentes',
        learning_and_change: 'Aprendizado e mudança',
        perceived_result: 'Resultado percebido',
        culture: 'Cultura',
        organizational_culture: 'Cultura organizacional',
        respondent_profile: 'Perfil dos Respondentes',
        group_view: 'Visão do Grupo',
        self_assessment: 'Autoavaliação',
        influence_capacity: 'Capacidade de Influência',
        leadership_profile: 'Perfil de Liderança',
        maturity_stage: 'Estágio de Maturidade',
        leadership_style: 'Estilo de Liderança',
        values_to_provide: 'Valores para fortalecer',
        values_to_adjust: 'Valores para ajustar ',
      },
    },
  },

  components: {
    average_card: {
      general: 'Média geral',
    },

    competence_card: {
      average_title: 'Médias por grupos de resposta',
    },

    star_question: {
      select_a_number: 'Selecione um número',
    },

    score_question: {
      good: 'Muito bom',
      bad: 'Muito ruim',
    },

    terms_of_use: {
      read: 'Eu li e concordo com os',
      terms_link: 'Termos de Uso',
      and: 'e a',
      policies_link: 'Política de Privacidade',
      platform: 'da Plataforma Humanizadas.',
    },

    table_pagination: {
      items_per_page: 'Itens por página',
      count: '{isPlural, select, true {Itens cadastrados} false {Item cadastrado}}',
      page: 'Pág',
      more_button: 'Carregar mais',
    },

    values_matrix: {
      amount: 'Quantidade de características selecionadas',
    },
  },

  forms: {
    login: {
      email_label: 'Email',
      email_placeholder: 'Ex.: mariana@email.com',
      password_label: 'Senha',
      forgot_password: 'Esqueci minha senha',
      forgot_email: 'Esqueci meu email',
      login_button: 'Entrar',
      signup_button: 'Criar conta',

      warning_dialog: {
        title: 'Você preencheu o email corretamente?',
        message: 'Certifique-se de que você já criou a sua conta e de que usou o email em que recebeu o convite para usar a plataforma.',
        cancel_button: 'Preenchi corretamente',
        confirm_button: 'Conferir',
      },
    },

    my_profile: {
      email_label: 'Email',
      email_placeholder: 'Ex.: mariana@email.com',

      first_name_label: 'Nome',
      first_name_placeholder: 'Ex.: Mariana',

      last_name_label: 'Sobrenome',
      last_name_placeholder: 'Ex.: Dias',

      current_password_label: 'Senha atual',
      password_label: 'Senha nova',
      confirm_password_label: 'Confirme sua nova senha',

      working_company_name_label: 'Organização na qual atua',
      working_company_name_placeholder: 'Ex.: Humanizadas',

      sector_label: 'Setor no qual atua',
      sector_placeholder: 'Ex.: Administrativo',

      role_label: 'Função na qual atua',
      role_placeholder: 'Ex.: Gerente Administrativo',

      biological_sex_label: 'Sexo',
      biological_sex_placeholder: 'Selecione',

      gender_label: 'Gênero',
      gender_placeholder: 'Ex.: Outro',
      gender_tooltip: 'A identidade de gênero se refere à experiência de uma pessoa com o seu próprio gênero',

      cancel_button: 'Cancelar',
      submit_button: 'Salvar',

      pcd_label: 'PcD (Pessoa com Deficiência)',
      pcd_placeholder: 'Selecione',

      pcd_tooltip_pt1_title: 'Deficiência física',
      pcd_tooltip_pt1_body: 'alteração completa ou parcial de um ou mais segmentos do corpo humano, acarretando o comprometimento da função física, apresentando-se sob a forma de paraplegia, paraparesia, monoplegia, monoparesia, tetraplegia, tetraparesia, triplegia, hemiplegia, hemiparesia, ostomia, amputação ou ausência de membro, paralisia cerebral, nanismo, membros com deformidade congênita ou adquirida, exceto as deformidades estéticas e as que não produzam dificuldades para o desempenho de funções',

      pcd_tooltip_pt2_title: 'Deficiência auditiva',
      pcd_tooltip_pt2_body: 'Perda parcial ou total das possibilidades auditivas sonoras, variando de graus e níveis na forma seguinte: de 41 a 55 db - surdez moderada | de 56 a 70 db - surdez acentuada | de 71 a 90 db - surdez severa | acima de 91 db - surdez profunda | anacusia - perda total',

      pcd_tooltip_pt3_title: 'Deficiência visual',
      pcd_tooltip_pt3_body: 'Deficiência visual caracteriza-se pela limitação ou perda da funções básicas do olho e do sistema visual. O deficiente visual pode ser a pessoa cega ou com baixa visão. A cegueira pressupõe a falta de percepção visual devido a fatores fisiológicos ou neurológicos. A cegueira total ou simplesmente amaurose, caracteriza-se pela completa perda de visão sem percepção visual de luz e forma. A cegueira pode ser congênita ou adquirida. A acuidade visual das pessoas com baixa visão é muito variável; mas, em geral, baixa visão é definida como uma condição na qual a visão da pessoa não pode ser totalmente corrigida por óculos, interferindo em suas atividades diárias, assim como a leitura e a locomoção.',

      pcd_tooltip_pt4_title: 'Deficiência mental',
      pcd_tooltip_pt4_body: 'Funcionamento intelectual significativamente inferior á média, com manifestação antes dos dezoito anos e limitações associadas a duas ou mais áreas de habilidades adaptativas, tais como: comunicação | cuidado pessoal | habilidades sociais | utilização da comunidade | saúde e segurança | habilidades acadêmicas | lazer | trabalho',

      color_label: 'Cor / Raça',
      color_placeholder: 'Selecione',
      color_tooltip: 'Conforme referência do IBGE',

      sexual_orientation_label: 'Orientação Sexual',
      sexual_orientation_placeholder: 'Selecione',
      sexual_orientation_tooltip: 'Orientação diz respeito à atração que se sente por outros indivíduos. Ela geralmente também envolve questões sentimentais, e não somente sexuais. Referência: UFSC',

      success_dialog: {
        title: 'Alterações salvas',
        message: 'Suas alterações foram salvas com sucesso!',
      },
    },

    organization_profile: {
      company_name_label: 'Nome da empresa',
      company_name_placeholder: 'Ex.: Humanizadas',
      company_corporate_name_label: 'Razão social',
      company_corporate_name_placeholder: 'Ex.: Humanizadas LTDA',

      email_label: 'E-mail',
      email_placeholder: 'Ex.: mariana@email.com',
      website_label: 'Site',
      website_placeholder: 'Ex.: www.site.com',
      cnpj_label: 'CNPJ',
      cnpj_placeholder: 'Ex.: 12.345.678/0001-99',
      state_registry_label: 'Inscrição estadual',
      state_registry_placeholder: 'Ex.: 000.000.00',
      foundation_date_label: 'Fundação',
      foundation_date_placeholder: 'Ex.: 01/01/2020',
      number_of_employees_label: 'Nº de colaboradores',
      number_of_employees_placeholder: 'Ex.: 100',
      foundation_city_label: 'Origem',
      foundation_city_placeholder: 'Ex.: Belo Horizonte',
      sector_label: 'Setor',
      sector_placeholder: 'Ex.: Serviços',

      company_type_label: 'Tipo',
      revenue_label: 'Receita',

      postal_code_label: 'CEP',
      postal_code_placeholder: 'Ex.: 12.345-678',
      street_label: 'Rua/avenida',
      street_placeholder: 'Ex.: Rua São Félix',
      number_label: 'Número',
      number_placeholder: 'Ex.: 10',
      neighborhood_label: 'Bairro',
      neighborhood_placeholder: 'Ex.: Planalto',
      complement_label: 'Complemento',
      complement_placeholder: 'Ex.: Sala 101',
      city_label: 'Cidade',
      city_placeholder: 'Ex.: Belo-Horizonte',
      state_label: 'Estado',
      state_placeholder: 'Ex.: Minas Gerais',

      select_placeholder: 'Selecione',
      cancel_button: 'Cancelar',
      submit_button: 'Salvar',

      select_options: {
        public: 'Pública',
        private: 'Privada',
        public_private: 'Pública-privada',
      },
    },

    respondents_builder: {
      first_name_label: 'Nome',
      first_name_placeholder: 'Ex.: Mariana',
      last_name_label: 'Sobrenome',
      last_name_placeholder: 'Ex.: Oliveira',
      email_label: 'Email',
      email_placeholder: 'Ex.: mariana@email.com',

      add_button: 'Adicionar outro',
      cancel_button: 'Cancelar',
      submit_button: 'Adicionar',
    },

    signup: {
      email_label: 'E-mail',
      email_placeholder: 'Ex.: mariana@email.com',
      first_name_label: 'Nome',
      first_name_placeholder: 'Ex.: Mariana',
      last_name_label: 'Sobrenome',
      last_name_placeholder: 'Ex.: Dias',
      cpf_label: 'CPF',
      cpf_placeholder: 'Ex.: 123.456.789-10',
      cpf_tooltip: 'Pedimos o seu CPF para validar sua identidade.',
      password_label: 'Senha',
      password_confirmation_label: 'Confirmar senha',
      login_button: 'Já possuo conta',
      signup_button: 'Criar cadastro',
      password_helper: 'Mínimo de 6 caracteres, 1 maiúscula e 1 minúscula',

      dialog: {
        title: 'Muito obrigado por se cadastrar!',
        message: 'Para garantir a sua segurança, caso deseje acessar a plataforma, você precisará entrar com usuário e senha na página de login.',
        confirm_button: 'Fazer login',
      },

      dialog_warning: {
        title: 'Atenção',
        message: 'Este link de cadastro é único para o e-mail {email} e só deve ser preenchido se você for o responsável por ele. Gostaria de continuar?',
        left_button: 'Não',
        right_button: 'Sim',
      },
    },

    support: {
      message_label: 'Mensagem',
      message_placeholder: 'Ex.: email não encontrado',
      cancel_button: 'Cancelar',
      submit_button: 'Enviar',

      dialog: {
        title: 'Fique de olho no seu email!',
        message: 'Enviaremos nos próximos dias mais informações para te dar suporte. Lembre-se de verificar também a sua caixa de spam.',
        confirm_button: 'Finalizar',
      },
    },

    unauthenticated_contact: {
      first_name_label: 'Nome',
      first_name_placeholder: 'Ex.: Mariana',
      last_name_label: 'Sobrenome',
      last_name_placeholder: 'Ex.: Oliveira',
      email_label: 'Email',
      email_placeholder: 'Ex.: mariana@email.com',
      message_label: 'Mensagem',
      message_placeholder: 'Ex.: email não encontrado',
      cancel_button: 'Cancelar',
      submit_button: 'Enviar',

      dialog: {
        title: 'Fique de olho no seu email!',
        message: 'Enviaremos nos próximos dias mais informações para te dar suporte. Lembre-se de verificar também a sua caixa de spam.',
        confirm_button: 'Finalizar',
      },
    },

    password_recovery_enter_email: {
      email_label: 'E-mail',
      email_placeholder: 'Ex.: mariana@email.com',

      cancel_button: 'Cancelar',
      confirm_button: 'Enviar',
      email_not_found: 'Email não encontrado. Preciso de ajuda',

      success_dialog: {
        title: 'Fique de olho no seu email!',
        message: 'Você irá receber um link para resetar sua senha. Lembre-se de verificar também a sua caixa de spam.',
        help_button: 'Não recebi o email',
        confirm_button: 'Finalizar',
      },

      warning_dialog: {
        title: 'Você preencheu o email corretamente?',
        message: 'Certifique-se de que você já criou a sua conta e de que usou o email em que recebeu o convite para usar a plataforma.',

        cancel_button: 'Preenchi corretamente',
        confirm_button: 'Conferir',
      },
    },

    password_recovery_reset_password: {
      password_label: 'Senha',
      password_confirmation_label: 'Confirmar senha',
      password_helper: 'Mínimo de 6 caracteres, 1 maiúscula e 1 minúscula',

      confirm_button: 'Enviar',

      dialog: {
        title: 'Sua senha foi alterada com sucesso!',
        message: 'Você já pode fazer login novamente',
        confirm_button: 'Fazer login',
      },
    },

    complete_register: {
      working_company_name_label: 'Empresa',
      working_company_name_placeholder: 'Ex.: Humanizadas',
      working_company_department_label: 'Setor',
      working_company_department_placeholder: 'Selecione',
      working_company_job_title_label: 'Função',
      working_company_job_title_placeholder: 'Selecione',
      gender_label: 'Gênero',
      gender_placeholder: 'Selecione',

      complete_register_dialog: {
        title: 'Conte-nos sobre você!',
        subtitle: 'Precisamos registrar mais algumas informações para completar seu cadastro.',
        subtitle2: 'As informações preenchidas, a partir daqui, serão utilizadas para fins de pesquisas de maneira agregada, garantindo portanto o seu anonimato.',
      },
    },
  },

  tables: {
    table_labels: {
      ideal_column: 'Am. ideal',
      minimum_column: 'Am. mínima',
    },

    respondents: {
      title: 'Respondentes',
      subtitle_1: 'Os contatos adicionados nesta página serão também adicionados à sua base, e estarão disponíveis no menu “Respondentes”.',
      subtitle_2: 'Atenção: você está adicionando respondentes ao questionário {questionnaire} do avaliado {user}.',
      subtitle_3: 'Atenção: você está adicionando respondentes {showTitle, select, true {ao questionário {title}} false {a este questionário}} {showUser, select, true {do avaliado {user}} false {}}.',
      empty: 'Não foi encontrado nenhum respondente',
      no_content: 'Não informado',

      actions: {
        invite: 'Convidar para avaliação',
        edit: 'Editar',
        delete: 'Excluir',
        send: 'Enviar um email',
      },

      filter: {
        department: 'Setor',
        jobTitle: 'Função',
      },

      dialogs: {
        empty: {
          title: 'Nenhum usuário selecionado.',
          body: 'Selecione ao menos um usuário na tabela para essa ação.',
        },
      },
    },

    assessments: {
      self: {
        filter: {
          title: 'Tipo',
          360: 'Avaliação 360',
          270: 'Avaliação 270',
          180: 'Avaliação 180',
          720: 'Avaliação 720',
          self_assessment: 'Autoavaliação',
        },
      },
      config: {
        view_questionnaire: 'Visualizar Questionários',
        config_assessment: 'Configurar avaliação',

        dialogs: {
          respondents: {
            title: 'Você ainda não possui nenhum respondente cadastrado na base.',
            body: 'Cadastre respondentes e facilite a configuração das suas avaliações.',

            left_button: 'Agora não',
            right_button: 'Cadastrar respondentes',
          },
        },
      },
      monitor: {
        show_report: 'Ver relatório',
        tooltips: {
          play: 'Retomar',
          pause: 'Pausar',
          finish: 'Finalizar agora',
          results: 'Ver resultado',
          view: 'Visualizar questionários',
        },

        pause_dialog: {
          title: 'Você deseja pausar a avaliação?',
          body_1: 'Enquanto a avaliação estiver pausada, os respondentes não poderão vê-la e nem enviar respostas. Você poderá retomá-la novamente a qualquer momento.',
          body_2: 'Atenção: a data de término não será alterada.',
        },

        stop_dialog: {
          title: 'Você deseja finalizar a avaliação?',
          body_1: 'Finalizando a avaliação, não será possível coletar mais respostas e nem liberá-la novamente. Os resultados serão feitos com base nos dados já recebidos.',
          body_2: 'Atenção: se você deseja suspender a avaliação durante um período, tente pausá-la.',
        },
      },
      release: {
        tooltips: {
          release: 'Liberar Agora',
          edit: 'Editar',
          view: 'Visualizar questionários',
        },


        header: {
          startWithin: 'Início em',
        },

        release_dialog: {
          title: 'Liberar avaliação agora?',
          body: 'Se você liberar a avaliação agora, ela será enviada para todos os respondentes. Essa ação não pode ser desfeita.',
          left_button: 'Aguardar data de início',
          right_button: 'Liberar',
        },
      },

      assessed_table: {
        tooltips: {
          view: 'Visualizar questionários',
          results: 'Ver resultado',
          monitor: 'Acompanhar',
        },
      },
    },

    questionnaire: {
      icons: {
        answer: 'Responder autoavaliação',
        monitor: 'Acompanhar respondentes',
      },
    },

    header: {
      bulk_actions: 'Ações em massa',
    },

    filters: {
      clean_filters: 'Limpar Filtros',
      apply_filters: 'Aplicar Filtros',
      assessment_title: 'Filtrar Avaliações',
      invited_option_start: 'Até {number} convidados',
      invited_option_middle: 'De {start} a {end} convidados',
      invited_option_end: 'Mais de {number} convidados',
      time_option_hour: 'Próximas {number}h',
      time_option_days: 'Próximos {number} dias',
      assessments_option_start: 'Até {number} avaliações',
      assessments_option_middle: 'De {start} a {end} avaliações',
      assessments_option_end: 'Mais de {number} avaliações',
      questions_option_start: 'Até {number} questões',
      questions_option_middle: 'De {start} a {end} questões',
      questions_option_end: 'Mais de {number} questões',
      assessed_option_start: 'Até {number} avaliados',
      assessed_option_middle: 'De {start} a {end} avaliados',
      assessed_option_end: 'Mais de {number} avaliados',
    },
  },

  dialogs: {
    reminder: {
      title: 'Enviar email',
      body: 'Escolha o email que deseja enviar para os respondentes selecionados. Ao enviar, este email será disparado e não será possível desfazer essa ação.',
      body_amount: 'Escolha o email que deseja enviar para {num, plural, one {o respondente selecionado} other {os respondentes selecionados}}. Ao enviar, este email será disparado e não será possível desfazer essa ação.',
      left_button: 'Cancelar',
      right_button: 'Enviar email',
    },
    send_email_all: {
      title: 'Enviar email para os selecionados?',
      title_number: 'Enviar email para {num, plural, one {# respondente} other {# respondentes}}?',
      body: 'Ao enviar, este email será disparado e não será possível desfazer essa ação. Para enviar um email para apenas alguns respondentes, selecione-os na tabela abaixo e clique em “Ações em massa”.',
      left_button: 'Cancelar',
      right_button: 'Enviar para todos',
    },
    email_successfully_sent: {
      title: 'Envio de {multiple, select, true {emails} false {email}}',
      body: '{multiple, select, true {Todos os emails foram enviados} false{O email foi enviado}} com sucesso!',
      button: 'Concluir',
    },
  },
};
