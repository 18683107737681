export default {
  criar_conta: {
    simple: 'Inicie Sesión',
  },

  entrar: {
    simple: 'Inicie Sesión',
  },

  olar: {
    simple: '¡Hola!',
    placeholder: '¡Hola {name}!',
  },

  plural: {
    simple: 'Tengo {num, plural, one { cat} other { cats}}',
  },

  select: {
    placeholder: 'Seleccione',
    simple: 'Soy {gender, select, male {man} female {woman}}',
  },

  layout_header: {
    options: {
      organizacional_profile: 'Perfil de la organización',
      my_profile: 'Mi cuenta',
      respondents: 'Encuestados de la organización',
      managers: 'Gerentes',
    },
    assessments: 'Evaluaciones',
    choose_view_mode: 'Cambiar a {isManagerView, select, true {respondedor} false {gerente}}',
    sign_out: 'Salir de mi cuenta',
    help: 'Necesito ayuda',
    see_all_companies: 'Ver todas las empresas',
    answer_assessments: 'Responder evaluaciones',
  },

  statuses: {
    questionnaires: {
      created: 'Creado',

      ready_to_start: 'No {gender, select, male {Iniciado} female {Iniciada}}',
      started: '{gender, select, male {Iniciado} female {Iniciada}}',
      answered: '{gender, select, male {Respondido} female {Respondida}}',

      not_answered: '{gender, select, female {No respondido} male {No respondido}}',
      partially_answered: 'No {gender, select, male {Finalizado} female {Finalizada}}',
      results_available: 'Ver Resultado',
    },

    assessments: {
      released: 'Liberada',
      paused: 'Pausada',
      finished: 'Finalizada',
      results: 'Resultados publicados',
    },

    see_all_companies: {
      companies: '{num, plural, one {Empresa} other {Empresas}}',
      desisted: '{num, plural, one {Desistiu} other {Desistiram}}',
      in_progress: '{num, plural, one {Liberada} other {Liberadas}}',
      paused: '{num, plural, one {Pausada} other {Pausadas}}',
      finished: '{num, plural, one {Finalizada} other {Finalizadas}}',
      results_available: '{num, plural, one {Ver resultado} other {Ver resultados}}',
    },

    respondents: {
      pending: 'Pendiente',
      registered: 'Registrado',
      not_answered: 'No respondido',
      answered: 'Respondido',
    },

    assessment_subtype: {
      self_assessment: 'Auto',
      180: '180',
      270: '270',
      360: '360',
      720: '720',
    },
  },

  user_roles: {
    manager: 'Gestor',
    owner: 'Gestor Principal',
    respondent: 'Encuestado',
  },

  errors: {
    required: {
      simple: 'Campo obligatorio',
      email: 'El e-mail es obligatorio',
      password: 'La contraseña es obligatoria',
      cpf: 'El CPF es obligatorio',
      cnpj: 'El CNPJ es obligatorio',
      first_name: 'Nombre es obligatorio',
      last_name: 'Apellido es obligatorio',
    },

    invalid: {
      start_date: 'Debe ser mayor a hoy',
      end_date: 'Debe ser mayor que la inicial',
      simple: 'Campo inválido',
      email: 'E-mail inválido',
      cpf: 'CPF inválido',
      cep: 'CEP inválido',
      cnpj: 'CNPJ inválido',
      url: 'URL inválido',
      value: 'Valor no permitido',
    },

    invalid_fields: 'Suas mudanças não serão salvas caso existam erros no formulário!',
    generic_error_message: 'Ups, ¡ocurrió un error al procesar su solicitud! Si persiste, por favor entrar en contacto a través del menú de soporte.',
    file_too_big: 'Archivo mayor a {num} MB',
    wrong_mime: 'Tipo de archivo inválido',
    passwords_do_not_match: 'Confirmación no corresponde con contraseña inserta',
    min_string_len: 'Mínimo de {num, plural, one {# caractere} other {# caracteres}}',
    max_string_len: 'Máximo de {num, plural, one {# caractere} other {# caracteres}}',
    min_uppercase_letters: 'Mínimo de {num, plural, one {# letra mayúscula} other {# letras mayúsculas}}',
    min_lowercase_letters: 'Mínimo de {num, plural, one {# letra minúscula} other {# letras minúsculas}}',
  },

  select_options: {
    // vou deixar estas chaves em pt para facilitar
    genders: {
      female: 'Mujer',
      male: 'Hombre',
      non_binary: 'No binario',
      fluid: 'Fluido',
      other: 'Otro',
      undeclared: 'Prefiero no declarar',
    },

    sex: {
      female: 'Femenino',
      male: 'Masculino',
      intersexual: 'Intersexual',
      other: 'Otro',
    },

    orientation: {
      assexual: 'Asexual',
      bissexual: 'Bisexual',
      straight: 'Heterosexual',
      homosexual: 'Homosexual',
      pansexual: 'Pansexual',
      fluid: 'Fluido',
      other: 'Otro',
      undeclared: 'Prefiero no declarar',
    },

    race: {
      yellow: 'Amarillo',
      white: 'Blanco',
      indian: 'Indígena',
      pardo: 'Pardo',
      black: 'Negro',
      undeclared: 'Prefiero no declarar',
    },

    pcd: {
      nao_pcd: 'No sou PcD',
      deficiencia_fisica: 'Deficiencia física',
      deficiencia_auditivo: 'Deficiencia auditiva',
      deficiencia_visual: 'Deficiencia visual',
      deficiencia_mental: 'Deficiencia mental',
      deficiencia_multipla: 'Deficiencia múltiple',
      undeclared: 'Prefiero no declarar',
    },

    job_titles: {
      estagiario: 'Pasantía',
      professor: 'Profesor',
      consultoria: 'Consultoría',
      supervisao: 'Supervisión',
      analista: 'Analista',
      assistente: 'Asistente',
      auxiliar: 'Auxiliar',
      supervisor: 'Supervisión',
      coordenador: 'Coordinación',
      gerente: 'Gerencia',
      senior: 'Especialista senior',
      diretor: 'Directorio (C-Level)',
      conselho: 'Consejo',
      prestador_de_servicos: 'Prestador de servicios',
      other: 'Otro',
    },

    departments: {
      administracao: 'Administración',
      assistencia_ou_suporte_tecnico: 'Asistencia o soporte técnico',
      atendimento: 'Atención',
      auditoria: 'Auditoría y consultoría',
      comercial: 'Comercial',
      compras: 'Compras',
      complience: 'Compliance',
      comunicacao_e_assessoria: 'Comunicación y asesoría',
      controladoria: 'Controladoría',
      contabilidade: 'Contabilidad',
      contratos: 'Contratos',
      engenharia: 'Ingeniería',
      estrategia: 'Estrategia',
      financeiro: 'Finanzas',
      inovacao: 'Innovación',
      inteligÃªncia: 'Inteligencia',
      juridico: 'Jurídico',
      logistica: 'Logística',
      manutencao: 'Mantenimiento',
      marketing: 'Marketing',
      melhoria: 'Mejora',
      novos_negocios: 'Nuevos negocios',
      operacoes: 'Operaciones',
      turismo: 'Turismo',
      pd: 'P&D',
      pos_vendas: 'Posventas',
      projetos: 'Proyectos',
      producao: 'Producción',
      processos: 'Procesos',
      qualidade: 'Calidad',
      rh_dho: 'RH/DHO',
      relacoes_institucionais: 'Relaciones institucionales',
      seguranca: 'Seguridad',
      servicos_tecnicos: 'Servicios técnicos',
      suprimentos: 'Suministros',
      tecnologia_da_informacao: 'Tecnología de la Información',
      other: 'Otro',
    },
  },

  navigation_menu: {
    assessments: {
      title: 'Evaluaciones',
    },

    multi_perspective_assessment_individual: {
      title: 'Avaliações de Indivíduos Multi-perspectiva',
    },

    individual_assessments: {
      title: 'Avaliações Individuais',
      self: 'Minhas avaliações',
      others: 'Avaliar pessoas',
    },

    multi_perspective_assessment: {
      title: 'Avaliações Multi-perspectivas',
    },

    respondents: {
      title: 'Encuestados',
    },

    organization_profile: {
      title: 'Perfil de Organización',
    },

    my_profile: {
      title: 'Mi perfil',
    },

    managers: {
      title: 'Gestores',
    },
  },

  indicators: {
    true: 'Sí',
    false: 'No',
    uninformed: 'No informado',
    placeholder: {
      money: 'Ex.: R$1.000.000',
      number: 'Ex.: 10',
      selector: 'Seleccione',
      percent: 'Ex.: 100%',
    },
  },

  tabnav: {
    informations: {
      id: 'Información',
      name: 'Información',
    },

    indicators: {
      id: 'Indicadores',
      name: 'Indicadores',
    },
  },

  emails: {
    manual: 'Envío manual',
    invite_template: {
      title: 'E-mail de invitación',
      date: 'Envío inmediato',
    },
    general_template: {
      title: 'Recordatorio General',
      date: 'Envío inmediato',
    },
    week_before_template: {
      title: 'Recordatorio - La semana antes del término',
      date: 'Un semana antes del término de la evaluación',
    },
    day_before_template: {
      title: 'Recordatorio -víspera del término',
      date: 'Un día antes del término de la evaluación',
    },
    results_available_template: {
      title: 'Liberación de resultados',
      date: 'Después del término de la evaluación',
    },
    application_email: {
      all: 'Enviar e-mail a todos los encuestados de esta evaluación',
      assessed: 'Enviar e-mail a este evaluado',
      unanswered: 'Enviar e-mail a no respondidos de este evaluado',
      role_type_title: {
        all: 'Enviar email para todos os que ainda não responderam?',
        internal: 'Enviar email para todos os stakeholders internos?',
        external: 'Enviar email para todos os stakeholders externos?',
      },
      role_type_body: {
        all: 'Este email será enviado para todos os respondentes cadastrados via email que ainda não concluíram a sua resposta. Após enviar, não é possível desfazer a ação.',
        internal: 'Ao enviar, este email será disparado para todos os respondentes cadastrados via email como stakeholders internos, e não será possível desfazer essa ação',
        external: 'Ao enviar, este email será disparado para todos os respondentes cadastrados via email como stakeholders externos, e não será possível desfazer essa ação.',
      },
    },
    collection_email: {
      all: 'Enviar a todos los evaluados y sus encuestados',
      assessed: 'Enviar a todos los evaluados',
      unanswered: 'Enviar a todos los no respondidos de todos los evaluados',
    },
  },

  progress_bar: {
    text: 'Tempo de {mobile, select, true {resp.} false {respostas}}',
  },

  words: {
    email: 'E-mail',
    email_simple: 'Email',
    password: 'Contraseña',
    sector: 'Área',
    gender: 'Género',
    company: 'Empresa',
    role: 'Papel',
    your_role: 'Tu papel',
    description: 'Descripción',
    behaviour: 'Comportamiento',
    instructions: 'Instrucciones',
    cancel: 'Cancelar',
    error: 'Error',
    close: 'Cerrar',
    continue: 'Continuar',
    finish: 'Finalizar',
    pause: 'Pausa',
    next: 'Próximo',
    previous: 'Anterior',
    status: 'Estatus',
    submit: 'Concluir',
    and: 'y',
    minimum: 'mínimo',
    maximum: 'máximo',
    minimum_of: 'Mínimo {num}',
    maximum_of: 'Máximo {num}',
    page: 'Página',
    available: 'Disponible',
    unavailable: 'Indisponible',
    of: 'de',
    address: 'Dirección',
    config: 'Configurar',
    release: 'Liberar',
    monitor: 'Monitorear',
    edit: 'Editar',
    view: 'Visualizar',
    actions: 'Acciones',
    required: 'Obligatorio',
    type: 'Tipo',
    questions: 'Preguntas',
    invited: 'Invitados',
    answered: 'Respondidos',
    not_answered: 'No respondido',
    answer: 'Respuesta',
    beginning: 'Inicio',
    end: 'Término',
    duration: 'Duración',
    add: 'Agregar',
    new: 'Nuevo',
    name: 'Nombre',
    position: 'Función',
    registered: 'Registrado',
    pending: 'Pendiente',
    manager: 'Gestor',
    owner: 'Gestor Principal',
    hour: 'hora',
    day: 'día',
    organization: 'Organización',
    individual: 'Individuo',
    sex: 'Sexo',
    pcd: 'PcD (Persona con Deficiencia)',
    color_race: 'Color/Raza',
    sexual_orientation: 'Orientación Sexual',
    assessed: 'Evaluado',
    assessment: 'Evaluación',
    self_assessment: 'Autoevaluación',
    respondent: 'Encuestado',
    respondents: 'Escuestados',
    back_main: 'Volver a la página inicial',
    manual_sending: 'Envío manual',
    date: 'Data',
    sampling: 'Amostra',
    positive: 'Positivo',
    negative: 'Negativo',
    neutral: 'Neutro',
    conclusion: 'Conclusão',
    recommendation: 'Recomendação',
    do_not_apply: 'No se aplica',
    search: 'Buscar',
    intro: 'Introducción',
    average: 'Promedio',
    in: 'en',
    general: 'General',
    participant: 'Partícipe',
    terms_of_use: 'Terminos de uso',
    privacy_policy: 'Política de privacidad',
    filter: 'Filtrar',
    occupation: 'Actuación',
    assessment_name: 'Nombre de Evaluación',
    assessment_status: 'Estado de Autoevaluación',
    choose: 'Elección',
    send_all: 'Enviar a todos',
    view_template: 'Ver plantilla',
    smallest_score: 'Grado menor',
    biggest_score: 'Grado maior',
    select_all: 'Comprobar todo',
    deselect_all: 'Deseleccionar todo',
    score: 'Puntuación',
    meaning: 'Significado',
    legend: 'Legenda',
    population: 'População',
    ideal_sample: 'Amostra ideal',
    minimum_sample: 'Amostra mínima geral',
    general_result: 'Resultado Geral',
    current: 'Atual',
    desired: 'Desejada',
    total: 'Total',
    come_back: 'Vuelve',
    start: 'Começar',
    assessed_organization: 'Organização avaliada',
    application: 'Aplicação',
    finished: 'Finalizada',
    initiation: 'Início',
    finale: 'Fim',
    participations: 'Participações',
    reports: 'Relatórios',
    factors: 'Fatores',
    subgroups: 'Subgrupos',

    plurals: {
      day: '{num, plural, one {# día} other {# días}}',
      hour: '{num, plural, one {# hora} other {# horas}}',
      answered: '{num, plural, one {Respondido} other {Respondidos}}',
      result: '{num, plural, one {Resultado} other {Resultados}}',
      assessed: '{num, plural, one {Avaliado} other {Avaliados}}',
      available: '{num, plural, one {# evaluación} other {# evaluaciones}}',
      tag: '{num, plural, one {tag} other {tags}}',
      question: '{num, plural, one {Questão} other {Questões}}',
      alternative: '{num, plural, one {Alternativa} other {Alternativas}}',
      answer: '{num, plural, one {Resposta} other {Respostas}}',
      companies: '{num, plural, one {Empresa} other {Empresas}}',
      people: '{num, plural, one {Pessoa} other {Pessoas}}',
    },

    try_again: 'Intentar nuevamente',
    delete: 'Excluir',
    confirm: 'Confirmar',
    save: 'Guardar',
    last_name: 'Apellido',
    not_informed: 'No informado',
  },

  checkbox: {
    title: {
      sector: 'Área',
      role: 'Función ',
      cba: 'CBA -Conscious Business Assessment',
      vyl: 'VYL - Valuable Young Leaders',
    },

    label: {
      Administrative: 'Administrativo',
      rh: 'RH',
      attendance: 'Atención',
      commercial: 'Comercial',
      self_employed: 'Autónomo',
      specialist: 'Especialista',
      manager: 'Gerente',
      ceo: 'CEO',
      leaderships: 'Liderazgo',
      collaborators: 'Colaboradores',
      clients_partners: 'Clientes y socios',
      partnerships: 'Sociedad',
      inidividual_assessment: 'Evaluación de individuos',
      organizational_assessment: 'Evaluación de Organizaciones',
    },
  },

  public_pages: {
    complete_register: {
      title: 'Cuéntanos sobre ti',
      subtitle: 'Necesitamos algunos datos acerca de ti para garantizar la legitimidad de la respuesta. Sin embargo, todas las respuestas son confidenciales y los datos van a ser utilizados de manera conjuntas para fines estadísticos. Solo el equipo de Humanizadas tiene acceso a las respuestas y tu anonimato para la empresa es garantizado.',

      email_label: 'E-mail',
      email_placeholder: 'mariana@email.com',
      i_dont_have_an_email: 'No poseo un e-mail',
      external_email: 'Respuesta a través de Link Abierto',

      first_name_label: 'Nombre',
      first_name_placeholder: 'Ex.: Mariana',

      last_name_label: 'Apellido',
      last_name_placeholder: 'Ex.: Dias',

      working_company_name_label: 'Empresa',
      working_company_name_placeholder: 'Ex.: Humanizadas',

      working_company_department_label: 'Área',
      working_company_department_placeholder: 'Seleccione',

      working_company_job_title_label: 'Función',
      working_company_job_title_placeholder: 'Seleccione',

      biological_sex_label: 'Sexo',
      biological_sex_placeholder: 'Seleccione',

      gender_label: 'Género',
      gender_placeholder: 'Seleccione',
      gender_tooltip: 'La identidad de género se refiere a la experiencia de una persona con su propio género',

      pcd_label: 'PcD (Persona con deficiencia)',
      pcd_placeholder: 'Seleccione',

      pcd_tooltip_pt1_title: 'Deficiencia física',
      pcd_tooltip_pt1_body: 'Alteración completa o parcial de uno o más segmentos del cuerpo humano, comprometiendo la función física, presentada bajo la forma de paraplegia, paraparesia, monoplegia, monoparesia, teraplegia, tetraparesia, triplegia, hemiplegia, hemiparesia, ostomia, amputación o ausencia de mienbro, parálisis cerebral, namismo, miembros con deformidad congénita o adquirida, excepto las deformidades estéticas y las que no produzcan dificultades para el desempeño de funciones.',

      pcd_tooltip_pt2_title: 'Deficiencia auditiva',
      pcd_tooltip_pt2_body: 'Pérdida parcial o total de las posibilidades auditivas sonoras, variando de grados y niveles en la forma siguiente',

      pcd_tooltip_pt3_title: 'Deficiencia visual',
      pcd_tooltip_pt3_body: 'La deficiencia visual se caracteriza por la limitación o pérdida de las funciones básicas del ojo o del sistema visual. El deficiente visual puede ser la persona ciega o con baja visión. La ceguera presupone la falta de percepción visual debido a factores fisiológicos o neurológicos. La ceguera total o simplemente amaurosis, se caracteriza por la completa pérdida de visión sin percepción visual de luz y forma. La ceguera puede ser congénita o adquirida. La agudeza visual de las personas con baja visión es muy variable; pero, en general, la baja visión es definida como una condición en la cual la visión de la persona no puede ser totalmente corregida con gafas, interfiriendo en sus actividades diarias, así como la lectura y la locomoción.',

      pcd_tooltip_pt4_title: 'Deficiencia mental',
      pcd_tooltip_pt4_body: 'Funcionamiento intelectual significativamente inferior al promedio, con manifestación antes de los dieciocho años y limitaciones asociadas a dos o más áread de habilidades adaptativas, tales como',

      color_label: 'Color/Raza',
      color_placeholder: 'Seleccione',
      color_tooltip: 'Según la referencia del IBGE',

      sexual_orientation_label: 'Orientación Sexual',
      sexual_orientation_placeholder: 'Seleccione',
      sexual_orientation_tooltip: 'Orientación se refiere a la atracción que se siente por otro individuos. Generalmente también involucra cuestiones sentimentales, y no solamente sexuales. Referencia',

      cpf_label: 'CPF',
      cpf_placeholder: 'Ex.: 123.456.789-10',
      cpf_tooltip: 'Pedimos su CPF para validar su identidad',
    },

    questionnaire: {
      warning_dialog: {
        title: '¿Comenzamos? Una vez iniciada, no es posible interrumpir la evaluación',
        body: 'Usted no podrá pausarla para finalizar más tarde. Esa restricción forma parte de nuestra metodología científica de investigación.',
        button: 'Responder de evaluación',
        left_button: 'Cancelar',
      },
    },
  },

  pages: {
    login: {
      title: '¡Qué bueno que usted regresó!',
      subtitle: 'Para acceder a su cuenta, inserte el e-mail en el que recibió la invitación y contraseña registrada.',
      stay_logged_in: 'Permanecer conectado',
    },

    signup: {
      title: '¡Estábamos esperándolo!',
      subtitle: 'Ya dejamos todo listo para usted. Para acceder a sus evaluaciones, basta con crear una cuenta.',
    },

    lastReport: {
      title: 'Aquí está el Informe general de la última edición de la encuesta.',
      subtitle: 'Haga clic en el enlace a continuación para acceder al informe general de la última edición de la Pesquisa Empresas Humanizadas do Brasil.',
      button: 'Informe de acceso',
    },

    thankYouRegister: {
      title: '¡Agradecemos su tiempo para ayudarnos a recopilar esta información!',
      subtitle: 'Regístrese y acceda a los Resultados Generales de la última edición de la encuesta.',
    },

    unauthenticated_contact: {
      title: 'Envíenos un mensaje y entraremos en contacto',
      subtitle: 'Cuéntenos más sobre el problema que encontró y buscaremos una solución',
    },

    password_recovery_enter_email: {
      title: 'Estamos aquí para ayudarte',
      subtitle: 'Digita tu email de registro y te enviaremos un link para crear una nueva contraseña',
    },

    password_recovery_reset_password: {
      title: 'Aquí está su nuevo comienzo.',
      subtitle: 'Cree su nueva contraseña y vuelva a aprovehcar todas las ventajas de la plataforma',
    },

    my_profile: {
      title: 'Mi perfil',
      edit_button: 'Editar',
    },

    organization_profile: {
      title: 'Perfil de la Organización',
      edit_button: 'Editar',
    },

    support: {
      title: 'Envíenos un mensaje y entraremos en contacto',
      subtitle: 'Cuéntenos más sobre el problema que encontró y buscaremos una solución',
    },

    respondents: {
      title: 'Encuestados',
      title_add: 'Agregar Encuestados',
      search_field: {
        placeholder: 'Buscar',
      },

      actions: {
        add_sheet: 'Agregar por planilla',
        download_template: 'Bajar modelo de planilla',
        upload_sheet: 'Hacer el upload de planilla',
        bulk_actions: 'Acciones en masa',
      },

      filter: {
        title: 'Filtrar',
      },

      add_button: 'Agregar',

      no_respondents: {
        message: 'Usted no agregó encuentados aún',
        button_text: 'Agregar ahora',
      },

      owner_informations_dialog: {
        label: {
          email: 'E-mail',
          company: 'Empresa',
          sector: 'Área',
          role: 'Función',
        },

        close_button: 'Cerrar',
      },

      configure_assessment_dialog: {
        title: '¿Usted desea configurar una evaluación ahora?',
        body: 'Ahora que agregó nuevos encuestados a la base, seleccione un tipo de evaluación para configurar.',

        close_button: 'Cerrar',
        config_button: 'Configurar',
      },

      respondents_filter_dialog: {
        title: 'Filtrar Encuestados',

        cancel_button: 'Cancelar',
        aplly_button: 'Aplicar filtros',
      },

      upload_and_download_sheet_dialog: {
        title: 'Recuerde usar el modelo de la planilla de upload de encuestados',
        body: 'Si usted aún no bajó el modelo, haga el download y adapte su planilla para que quede como nuestro estándar. Así, usted evitará errores en su base de encuestados.',

        download_button: 'Bajar modelo',
        upload_button: 'Hacer upload',
      },

      delete_respondents_dialog: {
        title: 'Excluir {num, plural, one {encuestado?} other {encuestados?}}',
        body: 'Al excluir este/estos encuestado/s, él/ellos no podrá/n acceder a ninguna de sus evaluaciones. En caso de que .... ya hayan respondido alguna, todos los datos serán excluídos de los cálculos de resultados.',
        body_attention: 'Atención',

        cancel_button: 'Cancelar',
        delete_button: 'Excluir',
      },

      delete_enrollments_dialog: {
        title: 'Excluir {num, plural, one {encuestado?} other {encuestados?}}',
        body: 'Al excluir ...., no podrá/n visualizar la evaluación y dejará de recibir los emails referentes a ella. En caso de que alguno haya respondido a las preguntas, los datos serán excluidos de los cálculos de resultados.',
        body_attention: 'Atención',

        cancel_button: 'Cancelar',
        delete_button: 'Excluir',
      },

      invitation_dialog: {
        title: 'Invitar a la evaluación',
        body: 'Seleccione la evaluación para la cual desea invitar a los encuestados que usted seleccionó',
        empty: 'No hay evaluaciones registradas en su organización',
        cancel_button: 'Cancelar',
        invitation_button: 'Invitar',
      },

      edit: {
        title: 'Editar Encuestado',
      },

      add: {
        title: 'Agregar Encuestados',
      },

      add_list: {
        title: 'Agregar Encuestados a',
        return: 'Volver a la pantalla de configuración',
        cancel_dialog: {
          title: '¿Usted desea salir sin guardar?',
          body: 'Si usted sale ahora, perderá toda la información que completó',
          left_button: 'Salir sin guardar',
          right_button: 'No salir',
        },
      },

      confirmation_message_dialog: {
        title: '¡Muchas gracias!',
        body: 'Agradecemos por tu tiempo en brindarnos esta información',

        button_finish: 'Finalizar',
        button_check: 'Verificar respuestas',
      },
    },

    assessments: {
      finalize_assessment_dialog: {
        title: '¿Usted desea finalizar la evaluación?',
        body: 'Al finalizar la evaluación, no será posible colectar más respuestas ni liberarlas nuevamente. Los resultados serán hechos en base a los datos ya recibidos.',
        body_attention: 'Atención',

        cancel_button: 'Cancelar',
        finalize_button: 'Finalizar',
      },

      send_email_dialog: {
        title: 'Enviar e-mail a {num, plural, one {# encuestado} other {# encuestados}}?',
        body: 'Al enviar, este e-mail será disparado y no será posible deshacer la acción. Para enviar un e-mail a apenas algunos encuestados, selecciónelos en la tabla debajo y haga click en acciones en masa.',

        cancel_button: 'Cancelar',
        everyone_button: 'Enviar a todos',
      },

      reminder_email_dialog: {
        title: 'Enviar e-mail',
        body: 'Seleccione el e-mail que desea enviar a ----. Al clickear enviar, este e-mail será disparado y no será posible deshacer esa acción.',

        cancel_button: 'Cancelar',
        everyone_button: 'Enviar a todos',
      },
    },

    organizational_assessments: {
      title: 'Avaliações Individuais',

      answered_tooltip: '¡Estamos calculando los resultados!',

      read_only_dialog: {
        title: 'Usted ya respondió esta evaluación',
        message: 'Ningún campo podrá ser alterado. ¿Usted desea visualizar la respuesta enviada?',
        submit_button: 'Ver respuesta',
      },

      no_invites: {
        message: 'Usted aún no fue invitado a responder ninguna evaluación de organizaciones',
        button_text: 'Necesito ayuda',
      },

      empty_search: {
        message: 'No fue encontrado ningún resultado para la búsqueda. Pruebe los filtros para más opciones',
      },
    },

    config_assessments: {
      success_dialog: {
        title: '¡Evaluación configurada y lista para liberar!',
        body: 'Esta evaluación estará ahora disponible en el menú --- Será iniciada automáticamente en la fecha de inicio programada, y podrá ser editada hasta ---',
        button_text: 'Concluir',
      },

      success_dialog_2: {
        title: '¡Evaluación configurada correctamente!',
        body: 'Después de configurar todos los cuestionarios, la evaluación ahora estará disponible en el menú "Liberar". Se iniciará automáticamente en la fecha de inicio programada y se puede editar hasta entonces.',
        button_text: 'Okay',
      },

      cancel_dialog: {
        title: '¿Usted desea salir sin guardar?',
        body: 'Si usted sale ahora, perderá toda la información que completó',
        left_button: 'Salir sin guardar',
        right_button: 'No salir',
      },

      release_dialog: {
        title: '¿Desea liberar evaluación sin guardar las modificaciones recientes?',
        body: 'Si usted desea guardar las modificaciones antes de liberar la evaluación, haga click en ---- debajo y guarde las configuraciones antes de continuar.',
        left_button: 'Liberar sin guardar',
        right_button: 'No liberar',
      },

      template_dialog: {
        use_template: 'Enviar este e-mail a los encuestados',
        send_date: 'Fecha de envío',
        subject: 'Asunto del mensaje',
        body: 'Cuerpo del mensaje',
      },

      save_config: 'Salvar',
      email_section: 'Envío de e-mails',
      email_instructions: 'Seleccione cuáles modelos de e-mail usted enviará a los encuestados invitados durante el curso de su evaluación',
      start_date: 'Fecha de inicio',
      end_date: 'Fecha de término',
      link: 'https',
      link_title: 'Link de compartir a {name}',
      copy_link: 'Copiar link',

      invite_template: {
        title: 'E-mail de invitación',
        date: 'Envío inmediato',
      },
      general_template: {
        title: 'Recordatorio general',
        date: 'Envío inmediato',
      },
      week_before_template: {
        title: 'Recordatorio - La semana antes del término',
        date: 'Una semana antes del término de la evaluación',
      },
      day_before_template: {
        title: 'Recordatorio - víspera del término',
        date: 'Un día antes del término de la evaluación',
      },
      results_available_template: {
        title: 'Liberación de resultados',
        date: 'Después del término de la evaluación',
      },

      header_items: {
        role: 'Papel de los resp.',
      },

      header_menu: {
        release: 'Liberar ahora',
        play: 'Retomar',
        pause: 'Pausar',
        finish: 'Finalizar ahora',
        results: 'Ver resultado',
        view: 'Visualizar cuestionarios',
        answer_self_assessment: 'Responder autoevaluación',
      },

      respondents_section: 'Encuestados',
      respondents_instructions: 'Además de los encuestados agregados por esta área, usted podrá invitar también a otras personas para responder su evaluación a través de un link que será disponibilizado después de liberar la evaluación',
      empty_table: 'Aún no fue disponibilizado ningún modelo para su organización',
    },

    see_all_companies: {
      company_dialog: {
        general_public_respondents: 'Respondentes (público geral)',
        specific_public_respondents: 'Respondentes (Públicos específicos)',
        specific_public: 'Públicos específicos',
      },
      organization_table_title: 'Todas as Avaliações de Organizações',
      individual_table_title: 'Todas as Avaliações de Indivíduos',
      self_assessment_table_title: 'Todas as Autoavaliações',
    },

    individual_assessments: {
      title: 'Evaluaciones individuales',
      manager: {
        email: {
          manual: 'Envío manual',
          assessed_respondents: 'Enviar a todos los evaluados y sus encuestados',
          assessed: 'Enviar a todos lo evaluados',
          not_answered_assessed: 'Enviar a todos los no respondidos de todos los evaluados',
        },
        invite: {
          warning: 'Marque la caja a la izquierda del nombre de cada encuestado para invitarlo',
          respondents: 'Además de los encuestados agregados por esta área, usted podrá invitar también a otras personas para responder su evaluación a través de un link abierto, que será disponibilizado después de liberar la evaluación',
        },
      },
      title_self: 'Minhas avaliações',
      title_others: 'Avaliar pessoas',

      read_only_dialog: {
        title: 'Usted ya respondió esta evaluación',
        message: 'Ningún campo podrá ser alterado. ¿Usted desea visualizar la respuesta enviada?',
        submit_button: 'Ver respuesta',
      },

      no_invites: {
        message: 'Usted aún no ha sido invitado a responder ninguna evaluación de individuos',
        button_text: 'Necesito ayuda',
      },

      empty_search: {
        message: 'No fue encontrado ningún resultado para la búsqueda. Pruebe los filtros para más opciones',
      },
    },

    config_individual_assessments: {
      title: 'Configurar evaluación de individuos',
      confirm_button: 'Concluir configuraciones',
      dialog_complete: {
        title: '¡Evaluación configurada y lista para liberar!',
        body: 'Esta evaluación estará ahora disponible en el menú ---- Será iniciada automáticamente en la fecha de inicio programada, y podrá ser editada hasta ---',
        button: 'Concluir',
      },
      dialog_incomplete: {
        title: '¿Concluir configuaciones sin completar todos los evaluados disponibles?',
        body: 'El resto de las evaluaciones que usted adquirió continuarán en el menú  ---- para que sean configuradas y liberadas posteriormente',
        left_button: 'Continuar configurando',
        right_button: 'Concluir',
      },
      dialog_add: {
        title: 'Adicionar nuevo usuario',
        title_edit: 'Editar usuario',
        subtitle: 'Este usuario aún no está registrado. Para invitarlo como evaluado en una evaluación, complete sus datos abajo.',
        email_label: 'E-mail del evaluado',
      },
      info: {
        title: 'Configuraciones generales de la ---',
        date_title: 'Inicio y término',
        date_subtitle: 'Las fechas de inicio y término de esa evaluación serán las mismas para todos los evaluados configurados abajo',
        start_date: 'Fecha de inicio',
        end_date: 'Fecha de término',
      },
      enrollments: {
        title: 'Configurar',
      },
      details: {
        email: 'E-mail del evaluado',
        warning: 'Marque la caja a la izquierda del nombre de cada encuestado para invitarlo',
        respondents: 'Además de los encuestados agregados por esta área, usted podrá invitar también a otras personas para responder su evaluación a través de un link abierto, que será disponibilizado después de liberar la evaluación.',
      },
    },

    release_individual_assessments: {
      title: 'Liberar evaluaciones de indiduos',

      assessment_dialog: {
        title: '¿Liberar evaluación ahora?',
        body: 'Si usted libera la evaluación ahora, será enviada a todos los encuestados. No se puede deshacer esa acción',
        release_button: 'Liberar',
        wait_button: 'Esperar fecha de inicio',
      },

      success_save_changes_dialog: {
        title: '¡Modificaciones guardadas exitosamente!',
        body: 'Esta evaluación no se iniciará automáticamente en la fecha de inicio programada, y puede ser editada hasta...',
        conclude_button: 'Concluir',
      },

      release_assessed: {
        title: 'Liberar{type, plural, one {Autoavaliação} other {Avaliação #}} - {title}',
        email_label: 'E-mail del evaluado',
      },
    },

    monitor_individual_assessments: {
      title: 'Seguir evaluaciones de individuos',

      finish_dialog: {
        title: '¿Usted desea finalizar la evaluación?',
        body: 'Al finalizar la evaluación no será posible colectar más respuestas ni liberarla nuevamente. Los resultados serán calculados en base a los datos ya recibidos.',
      },

      pause_dialog: {
        title: '¿Usted desea pausar la evaluación?',
        body: 'Mientras la evaluación está en pausa, los encuestados no podrán verla ni enviar respuestas. Puede reanudarlo en cualquier momento.',
        warn: 'Atención: la fecha de finalización no se modificará.',
      },

      invite: {
        all: 'Enviar a todos los evaluados y a sus encuestados',
        assessed: 'Enviar a todos los evaluados',
        not_answered: 'Enviar a todos los no respondidos de todos los evaluados',
      },

      assessed_invite: {
        all: 'Enviar email a todos los encuestados de este evaluado',
        assessed: 'Enviar e-mail a este evaluado',
        not_answered: 'Enviar e-mail a no respondidos de este evaluado',
      },

      general: {
        title: 'Monitorear',
        date_save_button: 'Guardar fecha',
      },

      assessed_user: {
        status: 'Estatus de autoevaluación',
      },
    },

    release_assessments: {
      table_title: 'Liberar Avaliações',
      table_empty: 'Usted aún no posee ninguna evaluación liberada para monitorear',
    },

    monitor_assessments: {
      table_title: 'Acompanhar Avaliações',
      table_empty: 'Usted aún no posee ninguna evaluación liberada para monitorear',
    },

    organizational_assessments_manager: {
      title: 'Configurar Avaliações',

      read_only_dialog: {
        title: 'Usted ya respondió esta evaluación',
        message: 'Ningún campo podrá ser alterado. ¿Usted desea visualizar la respuesta enviada?',
        submit_button: 'Ver respuesta',
      },

      empty_table: 'Aún no está disponible ningún modelo para su organización',

      no_invites: {
        message: 'Usted aún no ha sido invitado a responder ninguna evaluación de individuos',
        button_text: 'Necesito ayuda',
      },

      empty_search: {
        message: 'No fue encontrado ningún resultado para la búsqueda. Pruebe los filtros para más opciones.',
      },
    },

    questionnaire: {
      progress: 'Parte {start} de {end}',
      back_to_main_page: 'Volver a la página inicial',
      answer_all_required: 'Por favor, complete todos los campos obligatorios',
      matrix_error: 'Seleccione al menos un ítem',
      assessment_description: 'Descripción de la evaluación',
      exit_view: 'Salir de la vista previa',

      warning_dialog: {
        title: 'Conclusión',
        message: 'Para concluir la evaluación haga click en enviar respuestas, o haga click para verificar en caso de querer editar sus respuestas',
        cancel_button: 'Conferir respuestas',
      },

      success_dialog: {
        title: '¡Muchas gracias!',
        message: 'Agradecemos por tu tiempo en brindarnos esta información',
      },
    },

    managers: {
      confirm_demote_dialog: {
        title: '¡Ups! Usted dejó de ser el Gestor Principal de la empresa',
        body: 'Usted ya no podrá editar los usuarios ni el perfil de la organización',
      },

      confirm_promote_dialog: {
        title: 'Transferir actuación de Gestor Principal a ...',
        body: 'Al cambiar el usuario de Gestor Princial, usted perderá ese papel. Solamente el Gestor Principal puede editar los Gestores y el Perfil de la Organización',

        button_submit: 'Pasar a ser Gestor Principal',
      },

      confirm_destroy_dialog: {
        title: 'Excluir Gestor',
        body: 'Al excluir este Gestor ya no podrá acceder a la plataforma de su organización ni gerenciar las evaluaciones o encuestados',
      },

      add_manager_dialog: {
        title: 'Agregar Gestor',
        body: 'Para agregar un Gestor, es necesario que él se registre a través del link debajo',
      },

      user_not_found_dialog: {
        title: 'Ups... No encontramos ningún registro con este e-mail',
        body: 'Certifique que la persona que usted quiere invitar ya esté registrada y verifique si digitó el e-mail correctamente.',
      },

      table: {
        title: 'Gestores',
        empty_table: 'No fue encontrado ningún gestor',
      },

      dialog: {
        email: 'E-mail',
        company: 'Empresa',
        sector: 'Área',
        position: 'Función',
      },
    },

    messages: {
      thank_you: {
        title: '¡Muchas gracias!',
        subtitle: 'Agradecemos por tu tiempo en brindarnos esta información',
        alt_desktop: 'La imagen muestra dos personas frente a una computadora',
        alt_mobile: 'La imagen muestra dos personas frente a un celular',
        subtitle_desktop: 'Ilustración creada por pikisuperstar',
        subtitle_mobile: 'Ilustración creada por pikisuperstar y pch.vector',
      },
      thank_you_public: {
        title: '¡Muchas gracias!',
        subtitle: 'Agradecemos por tu tiempo en brindarnos esta información',
        subtitle_2: 'En caso de que desee aprovechar aún más la experiencia de la Plataforma Humanizadas, acceda a su cuenta por medio de una computadora.',
        alt_desktop: 'La imagen muestra dos personas frente a una computadora',
        alt_mobile: 'La imagen muestra dos personas frente a una computadora',
        subtitle_desktop: 'Ilustración creada por pikisuperstar',
        subtitle_mobile: 'Ilustración creada por pikisuperstar y pch.vector',
      },
      assessment_expired: {
        title: 'Investigación finalizada',
        subtitle: 'El tiempo de respuesta de esa evaluación ya terminó. ¡Le agradecemos por su interés!',
        alt_desktop: 'La imagen muestra una persona frente a una computadora',
        alt_mobile: 'La imagen muestra una persona frente a una computadora',
        subtitle_desktop: 'Ilustración creada por pikisuperstar',
        subtitle_mobile: 'Ilustración creada por pikisuperstar y pch.vector',
      },
      assessment_not_started: {
        title: 'Avaliação não iniciada.',
        subtitle: 'Essa avaliação ainda não está recebendo respostas, verifique com quem lhe encaminhou esse link qual a data de início correta. Esperamos lhe entregar uma excelente experiência. Agradecemos pelo seu tempo!',
        alt_desktop: 'A imagem mostra uma pessoa em frente a um computador',
        alt_mobile: 'A imagem mostra uma pessoa em frente a um computador',
        subtitle_desktop: 'Ilustração criada por pikisuperstar',
        subtitle_mobile: 'Ilustração criada por pikisuperstar & pch.vector',
      },
      mobile_not_available: {
        title: 'Ups...',
        subtitle: 'Disculpe las molestias. Aún estamos construyendo la experiencia Humanizadas para que usted pueda acceder via smartphone. Mientras tanto, usted podrá acceder a la plataforma únicamente por computadora.',
        alt_desktop: 'La imagen muestra dos personas frente a una computadora',
        alt_mobile: 'La imagen muestra dos personas frente a un celular',
        subtitle_desktop: 'Ilustración creada por pikisuperstar',
        subtitle_mobile: 'Ilustración creada por pikisuperstar y pch.vector',
      },
      error: {
        title: 'Error',
        subtitle: 'Página no encontrada',
        errorMessage: 'Lo sentimos mucho, pero hubo un error durante la navegación. Intente una de las alternativas abajo.',
        alt_desktop: 'La imagen muestra dos personas frente a una computadora',
        alt_mobile: 'La imagen muestra dos personas frente a una computadora',
        subtitle_desktop: 'Ilustración creada por pikisuperstar',
        subtitle_mobile: 'Ilustración creada por pikisuperstar y pch.vector',
      },
      invalid_link: {
        title: 'Enlace inválido',
        subtitle: 'Si está aquí es porque recibió un enlace único de otra persona.',
        body: 'Compruebe si ha recibido un correo electrónico de registro en su casilla de correo electrónico (incluido el correo no deseado) y, si no lo ha recibido, contáctenos a través de',
        email: 'suporte@humanizadas.com',
        alt_desktop: 'La imagen muestra a una persona frente a una computadora',
        alt_mobile: 'La imagen muestra a una persona frente a una computadora',
        subtitle_desktop: 'Diseñado por pikisuperstar',
        subtitle_mobile: 'Obra diseñada por pikisuperstar & pch.vector',
      },
    },

    terms_confirmation: {
      title: 'Aviso de Privacidad',
      subtitle: 'Hola, hemos actualizado la Política de privacidad y los Términos de uso de nuestra plataforma para brindarle más transparencia sobre cómo tratamos sus datos. Por lo tanto, para continuar usando nuestra plataforma, necesitamos que lea y acepte los términos a continuación:',
      confirm_button: 'Yo leo y estoy de acuerdo',
    },

    monitor_assessments_page: {
      title: 'Monitorear',
      respondents_section: 'Encuestados',
      header_menu: {
        answer_self_assessment: 'Responder autoevaluación',
      },
    },

    dashboard: {
      tables: {
        assessed_invite: {
          all: 'Enviar email para todos os respondentes {num, plural, one {deste avaliado} other {destes avaliados}}',
          assessed: 'Enviar email para {num, plural, one {este avaliado} other {estes avaliados}}',
          not_answered: 'Enviar email para não respondidos deste avaliado',
        },
        evaluators_link: 'Link avaliadores',
        assessed_link: 'Link avaliados',
        internal_link: 'Avaliadores Internos',
        external_link: 'Avaliadores Externos',
      },
      end_application: 'Para o fim da aplicação',
      send_reminder: 'Enviar lembrete',
      higher_answers: 'N° de respostas superior à amostra',
      lower_answers: 'N° de respostas inferior à amostra',
      absolute_number_answers: 'Respostas (números absolutos)',
      desired_sample: '{answers, plural, one {# resposta} other {# respostas}} /nacima da amostra desejada',
      achieve_ideal_sample: '{answers, plural, one {Falta # resposta} other {Faltam # respostas}} /npara atingir a amostra ideal',
      email_reminder: 'Emails e lembretes',
      completed_assessments: 'Avaliações concluídas',
      see_all: 'Ver todos',
      number_of_answers: 'Nº de Respostas',
      header: {
        play_and_pause: '{isPaused, select, true {Retomar} false {Pausar}} aplicação',
        view_reports: 'Ver Relatórios',
        view_group_reports: 'Ver Resultados do Grupo',
        export_reports: 'Exportar Relatório',
        share_reports: 'Compartilhar Resultados',
        view_questionnaire: 'Visualizar questionário',
        finish_application: 'Finalizar aplicação',
        share_link: 'Link de compartilhamento',
        internal_stakeholders: 'Stakeholders internos',
        external_stakeholders: 'Stakeholders externos',
        response_rate: 'Taxa de Respostas',
        calculate_results: 'Calcular resultados',
        results_being_calculate: 'Os resultados estão sendo calculados',
      },
      line_graph: {
        answers_per_day: 'Respostas por dia',
        total_answers: 'Respostas totais',
        number_of_responses: 'Nº de Respostas',
      },
      reminder: {
        automatic_emails: 'Emails automáticos',
        email_results_appraised: 'Email com Resultados para Avaliados',
        send_email_to_participants: 'Envie um email (pré-definido) para os participantes dessa avaliação',
        send_to_all_unanswered: 'Enviar para todos os não respondidos',
        send_email_to_appraised: 'Envie um email (pré-definido) disponibilizando os resultados para os avaliados',
        send_results_to_all_appraised: 'Enviar Resultados para todos os avaliados',
        send_all_internal_stakeholders: 'Enviar para todos os Stakeholders Internos',
        send_all_external_stakeholders: 'Enviar para todos os Stakeholders Externos',
      },
      individual: {
        conclusion_rate_summary: 'Resumo da Taxa de Conclusão',
        total_number_answers: 'N° total de Respostas',
        ideal_sample_reached: 'da Amostra Ideal atingida',
        desired_sample_reached: 'da Amostra Desejada atingida',
        minimum_sample_reached: 'da Amostra Miníma Geral atingida',
        engagement_by_appraisees: 'Engajamento por Avaliados',
        bigger_engagement: 'Maior engajamento',
        less_engagement: 'Menor engajamento',
        multiperspective_assessment: 'Avaliação de Multiperspectiva',
      },
      organization: {
        absolute_number_responses: 'N° absoluto de respostas',
        organization_assessment: 'Avaliação de Organizações',
      },
      self_assessment: {
        last_answers: 'Últimas Respostas',
        individual_self_assessment: 'Autoavaliação Individual',
      },
    },

    reports: {
      yours_answers: 'Tus respuestas',
      global_average: 'Media general: {value}',
      your_result: 'Tu resultado',
      answered_average: 'Encuestados promedio',
      less_developed: 'Menos desarrollado',
      more_developed: 'Más desarrollado',
      smallest_result: 'Resultado más bajo: {value}',
      biggest_result: 'Resultado más alto: {value}',

      competences_title: 'Habilidades',
      charts_title: 'Gráficos',
      behavior_title: 'Exponentes del comportamiento',
      development_title: 'Consejos de desarrollo',

      report_360: {
        title: 'Resultado de {type, plural, one {Autoevaluación} other {Evaluación #}} - {title}',
        valued_questions_title: 'Promedios por comportamiento',
        text_questions_title: 'Respuestas abiertas',
      },

      skeleton: {
        title: 'Descripción de la evaluación',
        description: 'Estás participando de la Investigación Empresas Humanizadas. Tiene como objetivo identificar las prácticas humanizadas presentes dentro de las Organizaciones a través de un proceso de escucha de todas las partes involucradas. Su contribución es muy importante para que la empresa pueda identificar cómo servirle mejor a usted y también a la Sociedad.',
      },
    },

    respondent_role: {
      title_individual: 'Hola, bienvenido, has sido invitado a responder a la evaluación de',
      title_organization: 'Hola, bienvenido, has sido invitado a responder a la evaluación de la organización',
      subtitle_organization: 'Seleccione su relación con la organización evaluada a continuación.',
      subtitle_individual: 'Seleccione a continuación cuál es su relación con {value}.',
    },
  },

  reports: {
    cba_cla: {
      tables: {
        total_group: 'Grupo total',
        completed_answers: 'Respostas completas',
        group_and_self_legend: 'Visão do Grupo e Autoavaliação',
        potentially_limiting: 'Potencialmente limitante',
      },
      graphs: {
        labels: {
          bigger: 'Maior valor',
          median: 'Mediana',
          smaller: 'Menor valor',
          gravity_center: 'CGC Atual',
        },
        group_legend: 'Visão do Grupo',
        axis: {
          very_high: 'Muito alta',
          high: 'Alta',
          medium: 'Mediana',
          low: 'Baixa',
          very_low: 'Muito baixa',
          stage_replace: 'Estágio {number}',
        },
      },
      recommendation_analysis: 'Recomendações para o processo de análise',
      individual_development: 'Plano de Desenvolvimento Individual',
      considerations: 'Considerações',
      recommendation: 'Recomendações',
      respondents_section: {
        reliability: 'Confiabilidade',
        applications: 'Aplicações',
      },
      cards_section: {
        group_view: 'Visão de Grupo',
        self_assessment: 'Autoavaliação',
        benchmark: 'Benchmark',
        influence: 'Influência',
        stage: 'Estágio',
      },
      one_page: {
        top_3_card_title: 'Top 3 | Cultura Organizacional',
      },
      graph_interpretation: {
        center: {
          bar_1: 'Sociedade',
          bar_2: 'Parceiros',
          bar_3: 'Clientes',
          bar_4: 'Colaboradores',
          bar_5: 'Lideranças',
          bar_6: 'Geral',
        },
        radar: {
          bar_1: 'Criação',
          bar_2: 'Realização',
          bar_3: 'Organização',
          bar_4: 'Colaboração',
          label_1: 'Experiência atual',
          label_2: 'Experiência desejada',
        },
      },
      labels: {
        difference: 'Diferença',
        benchmark: 'Benchmark',
        trust_level: 'Nível de confiança',
        perspective: 'Perspectiva Público {isInternal, select, true {Interno} false {Externo}}',
        meaning: 'Significado',
        score: 'Score',
        ratings_scale: 'Escala de ratings',
        level: 'Nível',
      },
      vertical_levels: {
        level_1: {
          title: 'Sobrevivência',
          description: 'foco na proteção e nas necessidades básicas da organização.<br/>Pode ocorrer manipulação, coerção, baixo nível de confiança e engajamento.',
          text: 'Autoproteção e necessidade de curto prazo.',
        },
        level_2: {
          title: 'Processos',
          description: 'foco na conformidade e estabilidade dos processos e relações.<br/>As regras e os padrões de trabalho governam o comportamento e atitude das pessoas.',
          text: 'Manutenção das normas e padrões vigentes,',
        },
        level_3: {
          title: 'Especialização',
          description: 'foco na especialização, nas competências e eficiência.<br/>As habilidades e as áreas de interesse dos indivíduos direcionam os comportamentos.',
          text: 'Especialização do processo e eficiência.',
        },
        level_4: {
          title: 'Resultados',
          description: 'foco nas análises, nos resultados e nas metas a serem atingidas.<br/>Alto nível de responsabilização e forte senso de meritocracia dominam as relações.',
          text: 'Entrega de resultados e efetividade.',
        },
        level_5: {
          title: 'Pessoas',
          description: 'foco na relações humanas e na interação com o sistema.<br/>A perspectiva de competição é instituída pela abundância e interdependência sistêmica.',
          text: 'Relações humanas e com o meio ambiente',
        },
        level_6: {
          title: 'Co-criação',
          description: 'foco na capacidade de integração e transformação sistêmica.<br/>As divergências passam a ser pontos de apoio para co-criar e co-inovar.',
          text: 'Propósito elevado e gestão dinâmica',
        },
        level_7: {
          title: 'Propósito',
          description: 'foco na sabedoria e na inteligência coletiva. Capacidade de sustentar tensões como complexidade e simplicidade, integridade e fractal.',
          text: 'A serviço do bem-estar da Humanidade',
        },
      },
      horizontal_levels: {
        level_1: {
          title: 'Perfeccionista',
          description: 'fazer a coisa certa, evitar enganos e trabalhar em busca de auto-desenvolvimento e autocontrole.',
        },
        level_2: {
          title: 'Prestativa',
          description: 'ser útil e confiável, estar disponível para as pessoas, antecipar e prover as necessidades dos outros.',
        },
        level_3: {
          title: 'Realizadora',
          description: 'conquistar metas, apresentar uma imagem que apoie o sucesso da organização, ser produtivo e performar.',
        },
        level_4: {
          title: 'Criativa',
          description: 'ser autêntico e expressar individualidade, ser conectado e verdadeiro com as emoções, ter significado e fazer a diferença no mundo.',
        },
        level_5: {
          title: 'Observadora',
          description: 'dar sentido ao mundo, economizar recursos, pensar, analisar, resolver, evitar dependência e perseguir o conhecimento.',
        },
        level_6: {
          title: 'Questionadora',
          description: 'criar estabilidade, confiança e segurança, proteger a organização, evitar ameaças e riscos, ser leal e responsável.',
        },
        level_7: {
          title: 'Entusiasta',
          description: 'sempre com uma perspectiva positiva, foco no futuro, perseguir prazer, liberdade e ampliar as possibilidades da vida.',
        },
        level_8: {
          title: 'Protetora',
          description: 'ser forte e controlar, evitar a fraqueza e vulnerabilidade, fazer as coisas acontecerem, tomar decisões e atingir resultados.',
        },
        level_9: {
          title: 'Pacificadora',
          description: 'promover harmonia e paz, receber influência das pessoas, criar conforto e rotinas, acolher e reduzir conflitos no grupo.',
        },
      },
    },
    cba: {
      title: 'CBA® One Page',
      error_margin: 'Margem de erro',
      confidence: 'Grau de confiança',
      print_button: 'Salvar PDF',

      engagement_section: 'Engajamento geral dos stakeholders',

      organizational_identity: {
        title: 'Identidade organizacional',
        vertical_development: 'Desenvolvimento Vertical (Atual)',
        horizontal_development: 'Desenvolvimento Horizontal (Atual)',
        values_to_develop: 'Top 3 Valores para Desenvolver',
        values_to_adjust_reframe: 'Top 3 Valores para Ajustar ou Ressignificar',
      },

      stakeholders_engagement: {
        title: 'Engajamento dos stakeholders',
        description: 'Resultado do grau de engajamento dos stakeholders do negócio (pontuação de -100 a +100). Pontuação negativa expressa desengajamento, e pontuação positiva expressa engajamento.',
      },

      stakeholders_management: {
        title: 'Princípios de gestão',
        description: 'Resultado da avaliação das práticas e dos resultados de gestão em cinco princípios (pontuação de 0 a 100).',
      },

      scores_scale: {
        focus: 'Foco',
        meaning: 'Significado',
        min_max: '{min} a {max}',

        quality: {
          title: 'Qualidade das relações',
          description: 'Índice de Qualidade das Relações (IQR)',
          '0_50': 'Relações extremamente deterioradas, colocando em risco o futuro do negócio.',
          '50_60': 'Relações com alto grau de criticidade, exigindo mudanças em caráter de urgência.',
          '60_70': 'Relações com problemas graves exigindo intervenções imediatas com as lideranças.',
          '70_80': 'Relações apresentam problemas críticos exigindo intervenções em sistemas internos.',
          '80_90': 'Relações com problemas pontuais exigindo atenção imediata para não se deteriorar.',
          '90_95': 'Relações positivas e bastante saudáveis.',
          '95_100': 'Relações extremamente positivas e saudáveis.',
        },

        maturity: {
          title: 'Maturidade organizacional',
          description: 'Centro de Gravidade de Consciência (CGC)',
          value1: 'Autoproteção e necessidades de curto prazo.',
          value2: 'Manutenção das normas e padrões vigentes.',
          value3: 'Especialização dos processos e eficiência.',
          value4: 'Entrega de resultados e efetividade.',
          value5: 'Relações humanas e com o meio ambiente.',
          value6: 'Propósito elevado e gestão dinâmica.',
          value7: 'A serviço do bem-estar da Humanidade.',
        },
      },

      engagement_messages: {
        '-100_-50': 'Na percepção dos stakeholders, a organização expressa um nível péssimo de engajamento com problemas que colocam em risco o futuro do negócio.',
        '-49_-1': 'Na percepção dos respondentes a organização expressa um nível positivo de engajamento, tendo relações com problemas pontuais exigindo atenção imediata para não se deteriorar.',
        '0_24': 'Na percepção dos stakeholders, a organização expressa um nível baixo de engajamento com problemas críticos que demandam atenção.',
        '25_49': 'Na percepção dos stakeholders, a organização expressa um nível bom de engajamento com problemas pontuais que demandam atenção.',
        '50_64': 'Na percepção dos stakeholders, a organização expressa um alto nível de engajamento com várias oportunidades para melhorias que devem ser exploradas.',
        '65_74': 'Na percepção dos stakeholders, a organização expressa um alto nível de engajamento com presença de oportunidades para melhorias.',
        '75_84': 'Na percepção dos stakeholders, a organização expressa um nível muito alto de engajamento e deve atuar para que continue assim.',
        '85_100': 'Na percepção dos stakeholders, a organização expressa um nível excelente de engajamento e provavelmente continuará assim no futuro.',
      },
    },
    focus: {
      header: {
        dimension: 'Dimensão',
        score: 'Score',
        scale: 'Escala',
      },
    },
    letter: {
      name: 'Pedro Ernesto Paro',
      role: 'CEO e fundador',
    },
    iqr_section: {
      levels: {
        '95_100': 'Relações extremamente positivas e saudáveis.',
        '90_95': 'Relações positivas e bastante saudáveis.',
        '80_90': 'Relações com problemas pontuais exigindo atenção imediata para não se deteriorar.',
        '70_80': 'Relações apresentam problemas críticos exigindo intervenções em sistemas internos.',
        '60_70': 'Relações com problemas graves exigindo intervenções imediatas com as lideranças.',
        '50_60': 'Relações com alto grau de criticidade, exigindo mudanças em caráter de urgência.',
        '0_50': 'Relações extremamente deterioradas, colocando em risco o futuro do negócio.',
      },
      labels: {
        '95_100': '95 a 100',
        '90_95': '90 a 95',
        '80_90': '80 a 90',
        '70_80': '70 a 80',
        '60_70': '60 a 70',
        '50_60': '50 a 60',
        '0_50': '0 a 50',
      },
    },
    organization_identity: {
      image_alt: 'gráfico de identidade organizacional',
    },
    narratives_analysis: {
      internal: 'Narrativas internas',
      external: 'Narrativas externas',
      title: 'As narrativas revelam...',
      levels: {
        level100: 'Relações extremamente positivas à serviço da Humanidade e da Vida.',
        level80: 'Relações saudáveis com problemas pontuais exigindo atenção.',
        level60: 'Relações saudáveis com problemas críticos exigindo intervenções.',
        level40: 'Relações com problemas graves exigindo intervenções imediatas com as lideranças.',
        level20: 'Relações com alto grau de criticidade, exigindo mudanças em caráter de urgência.',
        level0: 'Relações com alto grau de criticidade, exigindo mudanças em caráter de urgência.',
      },
    },
    archetypes_table: {
      cultural_orientation: 'Orientação Cultural',
    },
    components: {
      consolidated_results: {
        score: 'Score',
        sample_size: 'Tamanho amostra',
        margin_of_error: 'Margem de erro',
        trust_rating: 'Grau de confiança',
      },
      consolidated_levels: {
        very_high: 'Extremamente alto',
        high: 'Alto',
        middle: 'Mediano',
        low: 'Baixo',
        extremely_low: 'Extremamente baixo',
      },
      time_circles: {
        past: 'Passado',
        present: 'Presente',
        future: 'Futuro',
      },
      base_page: {
        answered_by: 'Respondido por',
      },
      center_chart: {
        optimistic_outlook: 'Perspectiva otimista',
        pessimistic_outlook: 'Perspectiva pessimista',
      },
      start_chart: {
        degree_of_maturity: 'Grau de maturidade organizacional',
      },
      multiple_bars_chart: {
        center_of_gravity: 'Centro de Gravidade',
      },
      side_menu: {
        menu_intro: 'Introdução',
        letter: 'Carta ao leitor',
        intro_comments: 'Comentários gerais',
        menu_rating: 'Rating',
        intro: 'Introdução aos ratings',
        general_results: 'Resultado geral',
        general_comments: 'Comentários gerais',
        focus: 'Onde focar',
        menu_perspective: 'Perspectiva multi-stakeholders',
        respondents: 'Respondentes',
        engagement: 'Engajamento',
        experience: 'Experiência',
        score: 'Satisfação',
        perspective: 'Perspectiva de futuro',
        menu_principles: 'Princípios',
        overview: 'Visão geral dos princípios',
        purpose: 'Propósito maior',
        strategy: 'Estratégia de valor',
        conscious_culture: 'Cultura consciente',
        adaptability: 'Adaptabilidade evolutiva',
        leadership: 'Liderança consciente',
        menu_values: 'Valores',
        menu_identity: 'Identidade',
        formation: 'Formação de identidade',
        vertical_dev: 'Desenvolvimento vertical',
        horizontal_dev: 'Desenvolvimento horizontal',
        quality: 'Índice de qualidade das relações',
        menu_narratives: 'Narrativas',
        map: 'Mapa de narrativas',
        main: 'Principais narrativas',
        analysis: 'Análise de conteúdo ',
        highlights: 'Práticas de destaque',
        recommended: 'Práticas recomendadas',
        demographics: 'Dados demográficos',
        reputation: 'Reputação',
        general_reputation: 'Reputação geral',
        reputation_by_stakeholder: 'Reputação por Stakeholder',
        responding_data: 'Dados respondentes',
        learning_and_change: 'Aprendizado e mudança',
        perceived_result: 'Resultado percebido',
        culture: 'Cultura',
        organizational_culture: 'Cultura organizacional',
        respondent_profile: 'Perfil dos Respondentes',
        group_view: 'Visão do Grupo',
        self_assessment: 'Autoavaliação',
        influence_capacity: 'Capacidade de Influência',
        leadership_profile: 'Perfil de Liderança',
        maturity_stage: 'Estágio de Maturidade',
        leadership_style: 'Estilo de Liderança',
        values_to_provide: 'Valores para fortalecer',
        values_to_adjust: 'Valores para ajustar ',
      },
    },
  },

  components: {
    average_card: {
      general: 'Media general',
    },

    competence_card: {
      average_title: 'Promedios por grupos de respuesta',
    },

    star_question: {
      select_a_number: 'Seleccione un número',
    },

    score_question: {
      good: 'Muy bueno',
      bad: 'Muy malo',
    },

    terms_of_use: {
      read: 'Leí y concuerdo con los',
      terms_link: 'Términos de uso',
      and: 'y',
      policies_link: 'Política de privacidad',
      platform: 'de la Plataforma Humanizadas',
    },

    table_pagination: {
      items_per_page: 'Ítems por página',
      count: '{isPlural, select, true {Itens cadastrados} false {Item cadastrado}}',
      page: 'Página',
      more_button: 'Carregar mais',
    },

    values_matrix: {
      amount: 'Número de características seleccionadas',
    },
  },

  forms: {
    login: {
      email_label: 'E-mail',
      email_placeholder: 'Ex.: mariana@email.com',
      password_label: 'Contraseña',
      forgot_password: 'Olvidé mi contraseña',
      forgot_email: 'Olvidé mi e-mail',
      login_button: 'Entrar',
      signup_button: 'Crear cuenta',

      warning_dialog: {
        title: '¿Usted escribió el e-mail correctamente?',
        message: 'Certifique que usted ya creó su cuenta y que usó el e-mail en el que recibió la invitación para usar la plataforma',
        cancel_button: 'Completé correctamente',
        confirm_button: 'Verificar',
      },
    },

    my_profile: {
      email_label: 'E-mail',
      email_placeholder: 'Ex.: mariana@email.com',

      first_name_label: 'Nombre',
      first_name_placeholder: 'Ex.: Mariana',

      last_name_label: 'Apellido',
      last_name_placeholder: 'Ex.: Dias',

      current_password_label: 'Contraseña actual',
      password_label: 'Contraseña nueva',
      confirm_password_label: 'Confirme su contraseña nueva',

      working_company_name_label: 'Empresa',
      working_company_name_placeholder: 'Ex.: Humanizadas',

      sector_label: 'Área',
      sector_placeholder: 'Ex.: Administrativo',

      role_label: 'Función',
      role_placeholder: 'Ex.: Gerente Administrativo',

      biological_sex_label: 'Sexo',
      biological_sex_placeholder: 'Seleccione',

      gender_label: 'Género',
      gender_placeholder: 'Selecione',
      gender_tooltip: 'La identidad de género se refiere a la experiencia de una persona con su propio género',

      cancel_button: 'Cancelar',
      submit_button: 'Guardar',

      pcd_label: 'PcD (Persona con Deficiencia)',
      pcd_placeholder: 'Seleccione',

      pcd_tooltip_pt1_title: 'Deficiencia Física',
      pcd_tooltip_pt1_body: 'Alteración completa o parcial de uno o más segmentos del cuerpo humano, comprometiendo la función física, presentándose bajo la forma de paraplegia, paraparesia, monoplegia, monoparesia, tetraplegia, tetraparesia, triplegia, hemiplegia, hemiparesia, ostomía, amputación o ausencia de miembro, parálisis cerebral, enanismo, miembros con deformidad congénita o adquirida, excepto las deformidades estéticas y las que no producen dificultades para el desempeño de funciones.',

      pcd_tooltip_pt2_title: 'Deficiencia auditiva',
      pcd_tooltip_pt2_body: 'Pérdida parcial o total de las posibilidades auditivas sonoras, variando de grados y niveles en la siguiente forma',

      pcd_tooltip_pt3_title: 'Deficiencia Visual',
      pcd_tooltip_pt3_body: 'La deficiencia visual se caracteriza por la limitación o pérdida de las funciones básicas del ojo o del sistema visual. El deficiente visual puede ser la persona ciega o con baja visión. La ceguera presupone la falta de percepción visual debido a factores fisiológicos o neurológicos. La ceguera total o simplemente amaurosis, se caracteriza por la completa pérdida de visión sin percepción visual de luz y forma. La ceguera puede ser congénita o adquirida. La agudeza visual de las personas con baja visión es muy variable; pero, en general, la baja visión es definida como una condición en la cual la visión de la persona no puede ser totalmente corregida con gafas, interfiriendo en sus actividades diarias, así como la lectura y la locomoción.',

      pcd_tooltip_pt4_title: 'Deficiencia mental',
      pcd_tooltip_pt4_body: 'Funcionamiento intelectual significativamente inferior al promedio, con manifestaciones antes de los dieciocho años y limitaciones asociadas a dos o más áreas de habilidades adaptativas, tales como',

      color_label: 'Color/Raza',
      color_placeholder: 'Seleccione',
      color_tooltip: 'Según referencia del IBGE',

      sexual_orientation_label: 'Orientación Sexual',
      sexual_orientation_placeholder: 'Seleccione',
      sexual_orientation_tooltip: 'Orientación en relación a la atracción que se siente por otros individuos. Generalmente también involucra cuestiones sentimentales y no solamente sexuales. Referencia: UFSC',

      success_dialog: {
        title: 'Alteraciones guardadas',
        message: '¡Sus alteraciones fueron guardadas exitosamente!',
      },
    },

    organization_profile: {
      company_name_label: 'Nombre de la empresa',
      company_name_placeholder: 'Ex.: Humanizadas',
      company_corporate_name_label: 'Razón social',
      company_corporate_name_placeholder: 'Ex.: Humanizadas LTDA',

      email_label: 'E-mail',
      email_placeholder: 'Ex.: mariana@email.com',
      website_label: 'Sitio',
      website_placeholder: 'Ex.: www.site.com',
      cnpj_label: 'CNPJ',
      cnpj_placeholder: 'Ex.: 12.345.678/0001-99',
      state_registry_label: 'Inscripción estadual',
      state_registry_placeholder: 'Ex.: 000.000.00',
      foundation_date_label: 'Fundación',
      foundation_date_placeholder: 'Ex.: 01/01/2020',
      number_of_employees_label: 'Nro. de colaboradores',
      number_of_employees_placeholder: 'Ex.: 100',
      foundation_city_label: 'Origen',
      foundation_city_placeholder: 'Ex.: Ciudad De Mexico',
      sector_label: 'Área',
      sector_placeholder: 'Ex.: Servicios',

      company_type_label: 'Tipo',
      revenue_label: 'Renta',

      postal_code_label: 'CEP',
      postal_code_placeholder: 'Ex.: 12.345-678',
      street_label: 'Calle/Avenida',
      street_placeholder: 'Ex.: Calle Félix',
      number_label: 'Número',
      number_placeholder: 'Ex.: 10',
      neighborhood_label: 'Barrio',
      neighborhood_placeholder: 'Ex.: Planalto',
      complement_label: 'Complemento',
      complement_placeholder: 'Ex.: Sala 101',
      city_label: 'Ciudad',
      city_placeholder: 'Ex.: Ciudad De Mexico',
      state_label: 'Estado',
      state_placeholder: 'Ex.: Ciudad De Mexico',

      select_placeholder: 'Seleccione',
      cancel_button: 'Cancelar',
      submit_button: 'Guardar',

      select_options: {
        public: 'Pública',
        private: 'Privada',
        public_private: 'Pública-privada',
      },
    },

    respondents_builder: {
      first_name_label: 'Nombre',
      first_name_placeholder: 'Ex.: Mariana',
      last_name_label: 'Apellido',
      last_name_placeholder: 'Ex.: Oliveira',
      email_label: 'E-mail',
      email_placeholder: 'Ex.: mariana@email.com',

      add_button: 'Agregar otro',
      cancel_button: 'Cancelar',
      submit_button: 'Agregar',
    },

    signup: {
      email_label: 'E-mail',
      email_placeholder: 'Ex.: mariana@email.com',
      first_name_label: 'Nombre',
      first_name_placeholder: 'Ex.: Mariana',
      last_name_label: 'Apellido',
      last_name_placeholder: 'Ex.: Dias',
      cpf_label: 'CPF',
      cpf_placeholder: 'Ex.: 123.456.789-10',
      cpf_tooltip: 'Pedimos su CPF para validar su identidad',
      password_label: 'Contraseña',
      password_confirmation_label: 'Confirmar contraseña',
      login_button: 'Ya estoy registrado',
      signup_button: 'Crear registro',
      password_helper: 'Mínimo de 6 caracteres, 1 maíuscula e 1 minúscula',

      dialog: {
        title: '¡Muchas gracias por registrarse!',
        message: '¡Ahora usted ya puede aprovechar la experiencia Humanizadas!',
        confirm_button: 'Hacer login',
      },

      dialog_warning: {
        title: 'Atención',
        message: 'Este enlace de registro es exclusivo del correo electrónico {email} y solo debe completarse si usted es responsable de él. ¿Te gustaria continuar?',
        left_button: 'No',
        right_button: 'Si',
      },
    },

    support: {
      message_label: 'Mensaje',
      message_placeholder: 'Ex.: El correo electrónico no encontrado',
      cancel_button: 'Cancelar',
      submit_button: 'Enviar',

      dialog: {
        title: 'Esté atento a su correo',
        message: 'Enviaremos en los próximos días más información para darle soporte. Recuerde verificar también su bandeja de Spam.',
        confirm_button: 'Finalizar',
      },
    },

    unauthenticated_contact: {
      first_name_label: 'Nombre',
      first_name_placeholder: 'Ex.: Mariana',
      last_name_label: 'Apellido',
      last_name_placeholder: 'Ex.: Dias',
      email_label: 'E-mail',
      email_placeholder: 'Ex.: mariana@email.com',
      message_label: 'Mensaje',
      message_placeholder: 'Ex.: El correo electrónico no encontrado',
      cancel_button: 'Cancelar',
      submit_button: 'Enviar',

      dialog: {
        title: 'Esté atento a su correo',
        message: 'Enviaremos en los próximos días más información para darle soporte. Recuerde verificar también su bandeja de Spam.',
        confirm_button: 'Finalizar',
      },
    },

    password_recovery_enter_email: {
      email_label: 'E-mail',
      email_placeholder: 'Ex.: mariana@email.com',

      cancel_button: 'Cancelar',
      confirm_button: 'Enviar',
      email_not_found: 'E-mail no encontrado. Necesito ayuda.',

      success_dialog: {
        title: 'Esté atento a su correo',
        message: 'Usted recibirá un link para resetear su contraseña. Recuerde verificar también su bandeja de Spam',
        help_button: 'No recibí el email',
        confirm_button: 'Finalizar',
      },

      warning_dialog: {
        title: '¿Usted completó su e-mail correctamente?',
        message: 'Verifique si usted ya creó su cuenta y si usó el e-mail en el que recibió la invitación para usar la plataforma.',

        cancel_button: 'Completé correctamente',
        confirm_button: 'Verificar',
      },
    },

    password_recovery_reset_password: {
      password_label: 'Contraseña',
      password_confirmation_label: 'Confirmar contraseña',
      password_helper: 'Mínimo de 6 caracteres, 1 mayúscula y 1 minúscula',

      confirm_button: 'Enviar',

      dialog: {
        title: '¡Su contraseña fue alterada exitosamente!',
        message: 'Usted ya puede hacer el login nuevamente',
        confirm_button: 'Hacer login',
      },
    },

    complete_register: {
      working_company_name_label: 'Empresa',
      working_company_name_placeholder: 'Ex.: Humanizadas',
      working_company_department_label: 'Área',
      working_company_department_placeholder: 'Seleccione',
      working_company_job_title_label: 'Función',
      working_company_job_title_placeholder: 'Seleccione',
      gender_label: 'Género',
      gender_placeholder: 'Seleccione',

      complete_register_dialog: {
        title: '¡Cuéntanos sobre ti!',
        subtitle: 'Necesitamos registrar más información para completar su registro',
        subtitle2: 'La información completada, a partir de ahora, será utilizada con fines de investigaciones de manera agregada, garantizando por lo tanto su anonimato.',
      },
    },
  },

  tables: {
    table_labels: {
      ideal_column: 'Am. ideal',
      minimum_column: 'Am. mínima',
    },

    respondents: {
      title: 'Encuestados',
      subtitle_1: 'Los contactos agregados en esta página también se agregarán a su base de datos y estarán disponibles en el menú "Encuestados"',
      subtitle_2: 'Atención: está agregando encuestados al {questionnaire} del usuario evaluado {user}.',
      subtitle_3: 'Atención: está agregando encuestados {showTitle, select, true {al cuestionario {title}} false {a este cuestionario}} {showUser, select, true {del usuario evaluado {user}} false {}}.',
      empty: 'No fue encontrado ningún encuestado',
      no_content: 'No informado',

      actions: {
        invite: 'Invitar para evaluación',
        edit: 'Editar',
        delete: 'Excluir',
        send: 'Enviar un e-mail',
      },

      filter: {
        department: 'Área',
        jobTitle: 'Función',
      },

      dialogs: {
        empty: {
          title: 'No se seleccionó ningún usuario.',
          body: 'Seleccione al menos un usuario en la tabla para esta acción.',
        },
      },
    },

    assessments: {
      self: {
        filter: {
          title: 'Tipo',
          360: 'Evaluación 360',
          270: 'Evaluación 270',
          180: 'Evaluación 180',
          720: 'Evaluación 720',
          self_assessment: 'Autoevaluación',
        },
      },
      config: {
        view_questionnaire: 'Visualizar cuestionarios',
        config_assessment: 'Configurar evaluación',

        dialogs: {
          respondents: {
            title: 'Usted aún no posee ningún encuestado registrado en la base',
            body: 'Registre encuestados y facilite la configuración de sus evaluaciones',

            left_button: 'Ahora no',
            right_button: 'Registrar encuestados',
          },
        },
      },
      monitor: {
        show_report: 'Ver relatório',
        tooltips: {
          play: 'Retomar',
          pause: 'Pausar',
          finish: 'Finalizar ahora',
          results: 'Ver resultado',
          view: 'Visualizar cuestionarios',
        },

        pause_dialog: {
          title: '¿Quieres pausar la evaluación?',
          body_1: 'Mientras la evaluación está en pausa, los encuestados no podrán verla ni enviar respuestas. Puede reanudarlo en cualquier momento.',
          body_2: 'Atención: la fecha de finalización no se modificará.',
        },

        stop_dialog: {
          title: '¿Quieres finalizar la evaluación?',
          body_1: 'Una vez finalizada la evaluación, no será posible recopilar más respuestas o publicarla nuevamente. Los resultados se realizarán en base a los datos ya recibidos.',
          body_2: 'Atención: si desea suspender la evaluación por un período, intente pausarla.',
        },
      },
      release: {
        tooltips: {
          release: 'Liberar ahora',
          edit: 'Editar',
          view: 'Visualizar cuestionarios',
        },


        header: {
          startWithin: 'Inicio en',
        },

        release_dialog: {
          title: '¿Liberar evaluación ahora?',
          body: 'Si usted libera la evaluación ahora, será enviada a todos los encuestados. No será posible deshacer esa acción.',
          left_button: 'Guardar fecha de inicio',
          right_button: 'Liberar',
        },
      },

      assessed_table: {
        tooltips: {
          view: 'Visualizar cuestionarios',
          results: 'Ver resultados',
          monitor: 'Monitorear',
        },
      },
    },

    questionnaire: {
      icons: {
        answer: 'Responder autoevaluación',
        monitor: 'Monitorear encuestados',
      },
    },

    header: {
      bulk_actions: 'Acciones masivas',
    },

    filters: {
      clean_filters: 'Borrar Filtros',
      apply_filters: 'Aplicar Filtros',
      assessment_title: 'Reseñas de Filtros',
      invited_option_start: 'Hasta {number} invitados',
      invited_option_middle: 'De {start} a {end} invitados',
      invited_option_end: 'Más de {number} invitados',
      time_option_hour: 'Siguientes {number}h',
      time_option_days: 'Siguientes {number} dias',
      assessments_option_start: 'Hasta {number} evaluaciones',
      assessments_option_middle: 'De {start} a {end} evaluaciones',
      assessments_option_end: 'Más de {number} evaluaciones',
      questions_option_start: 'Hasta {number} preguntas',
      questions_option_middle: 'De {start} a {end} preguntas',
      questions_option_end: 'Más de {number} preguntas',
      assessed_option_start: 'Hasta {number} calificados',
      assessed_option_middle: 'De {start} a {end} calificados',
      assessed_option_end: 'Más de {number} calificados',
    },
  },

  dialogs: {
    reminder: {
      title: 'Enviar e-mail',
      body: 'Seleccione el e-mail que desea enviar a los encuestados seleccionados. Al enviar este e-mail, será disparado y no será posible deshacer esa acción',
      body_amount: 'Seleccione el e-mail que desea enviar a ----. Al enviar este e-mail, será disparado y no será posible deshacer esa acción',
      left_button: 'Cancelar',
      right_button: 'Enviar e-mail',
    },
    send_email_all: {
      title: '¿Enviar e-mail a los seleccionados?',
      title_number: '¿Enviar e-mail a ...?',
      body: 'Al enviar este e-mail, será disparado y no será posible deshacer esa acción. Para enviar un e-mail a apenas algunos encuestados, selecciónelos en la tabla debajo y haga click en --- en masa',
      left_button: 'Cancelar',
      right_button: 'Enviar a todos',
    },
    email_successfully_sent: {
      title: 'Envío de {multiple, select, true {emails} false {email}}',
      body: '{multiple, select, true {Todos los correos electrónicos fueron enviados} false{El correo electrónico ha sido enviado}} con éxito!',
      button: 'Concluir',
    },
  },
};
